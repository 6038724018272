import { APP_COMPANY_BRANCHES } from '@/constants/localStorageVariables.js'

const getAppCompanyBranches = () => {
  try {
    const companyBranches = localStorage.getItem(APP_COMPANY_BRANCHES) || []
    return companyBranches.length ? JSON.parse(companyBranches) : []
  } catch (error) {
    console.error(error)
    return []
  }
}

export default getAppCompanyBranches
