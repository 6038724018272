import {
  UPDATE_OR_ADD_COMPANY_BRANCH,
  REMOVE_COMPANY_BRANCH,
  EMPTY_COMPANY_BRANCHES,
  SET_COMPANY_BRANCH_TOKEN,
  SET_PARTIAL_COMPANY_BRANCH
} from '@/store/mutationTypes.js'
import { APP_COMPANY_BRANCHES } from '@/constants/localStorageVariables.js'
import getAppCompanyBranches from '@/helpers/getAppCompanyBranches.js'

const state = () => ({
  companyBranches: getAppCompanyBranches(),
  companyBranchToken: null
})

const getters = {
  companyBranch: (state) => {
    const companyBranches = state.companyBranches
    const companyToken = state.companyBranchToken
    return companyBranches.find(item => item.companyToken === companyToken) || null
  },
  companyBranchUserRole: (state, getters) => {
    return getters.companyBranch?.userRole || null
  }
}

const actions = {
  updateOrAddCompanyBranch ({ commit }, { companyBranch }) {
    commit(UPDATE_OR_ADD_COMPANY_BRANCH, { companyBranch })
  },
  removeCompanyBranch ({ commit, state }, { companyToken }) {
    commit(REMOVE_COMPANY_BRANCH, { companyToken })
  },
  emptyCompanyBranches ({ commit }) {
    commit(EMPTY_COMPANY_BRANCHES)
  },
  setCompanyBranchToken ({ commit }, { companyToken }) {
    commit(SET_COMPANY_BRANCH_TOKEN, { companyToken })
  },
  setPartialCompanyBranch ({ commit, state }, { companyToken, data }) {
    commit(SET_PARTIAL_COMPANY_BRANCH, { companyToken, data })
  }
}

const mutations = {
  [UPDATE_OR_ADD_COMPANY_BRANCH] (state, { companyBranch }) {
    try {
      const companyBranches = getAppCompanyBranches()
      const companyBranchIndex = companyBranches.findIndex(item => item.companyToken === companyBranch.companyToken)

      if (companyBranchIndex >= 0) {
        companyBranches.splice(companyBranchIndex, 1)
      }

      companyBranches.unshift(companyBranch)
      state.companyBranches = companyBranches
      localStorage.setItem(APP_COMPANY_BRANCHES, JSON.stringify(companyBranches))
    } catch (error) {
      console.error(error)
    }
  },
  [REMOVE_COMPANY_BRANCH] (state, { companyToken }) {
    try {
      const companyBranches = getAppCompanyBranches()
      const index = companyBranches.findIndex(item => item.companyToken === companyToken)

      if (companyBranches.length === 0) {
        localStorage.removeItem(APP_COMPANY_BRANCHES)
        return
      }

      if (index === -1) { return }

      companyBranches.splice(index, 1)
      state.companyBranches = companyBranches
      localStorage.setItem(APP_COMPANY_BRANCHES, JSON.stringify(companyBranches))
    } catch (error) {
      console.error(error)
    }
  },
  [EMPTY_COMPANY_BRANCHES] () {
    try {
      localStorage.removeItem(APP_COMPANY_BRANCHES)
    } catch (error) {
      console.error(error)
    }
  },
  [SET_COMPANY_BRANCH_TOKEN] (state, { companyToken }) {
    state.companyBranchToken = companyToken
  },
  [SET_PARTIAL_COMPANY_BRANCH] (state, { companyToken, data }) {
    const companyBranches = getAppCompanyBranches()
    const companyBranch = companyBranches.find(item => item.companyToken === companyToken)
    if (!companyBranch) { return }

    for (const [key, value] of Object.entries(data)) {
      companyBranch[key] = value
    }
    state.companyBranches = companyBranches
    localStorage.setItem(APP_COMPANY_BRANCHES, JSON.stringify(companyBranches))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
