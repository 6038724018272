import {
  SET_DEFAULT_LAYOUT_LEFT_DRAWER_SHOW,
  SHOW_MODAL_NOT_PERMISSION,
  SHOW_APP_LOADING_OVERLAY
} from '@/store/mutationTypes.js'

const state = () => ({
  leftDrawer: {
    show: null,
    type: 'default',
    clipped: true,
    floating: false,
    mini: false
  },
  footer: {
    inset: false
  },
  showModalNotPermission: false,
  showAppLoadingOverlay: true
})

const getters = {
  appLogo: (state) => {
    return require('@/components/layouts/images/logo_white.svg')
  },
  appStyles: (state) => {
    return {}
  }
}

const actions = {
  setLeftDrawerShow ({ commit }, { show }) {
    commit(SET_DEFAULT_LAYOUT_LEFT_DRAWER_SHOW, { show })
  },
  setShowModalNotPermission ({ commit }, { show }) {
    commit(SHOW_MODAL_NOT_PERMISSION, { show })
  },
  setShowAppLoadingOverlay ({ commit }, { show }) {
    commit(SHOW_APP_LOADING_OVERLAY, { show })
  }
}

const mutations = {
  [SET_DEFAULT_LAYOUT_LEFT_DRAWER_SHOW] (state, { show }) {
    state.leftDrawer.show = show
  },
  [SHOW_MODAL_NOT_PERMISSION] (state, { show }) {
    state.showModalNotPermission = show
  },
  [SHOW_APP_LOADING_OVERLAY] (state, { show }) {
    state.showAppLoadingOverlay = show
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
