export const LANDING_BACKGROUND_FLAT = 'flat_color'
export const LANDING_BACKGROUND_GRADIENT = 'gradient'
export const LANDING_BACKGROUND_IMAGE = 'image'

export const LANDING_FORM_FILLED_SQUARED = 'filled_squared'
export const LANDING_FORM_FILLED_SEMI_ROUNDED = 'filled_semi_rounded'
export const LANDING_FORM_FILLED_ROUNDED = 'filled_rounded'
export const LANDING_FORM_HARDSHADOW_SQUARED = 'hardshadow_squared'
export const LANDING_FORM_HARDSHADOW_SEMI_ROUNDED = 'hardshadow_semi_rounded'
export const LANDING_FORM_HARDSHADOW_ROUNDED = 'hardshadow_rounded'

export const BACKGROUND_COLOR = '#FFFFFF'
export const PRIMARY_COLOR = '#F51414'
export const SECONDARY_COLOR = '#FF8D08'
export const FONT_COLOR = '#000000'

export const LAYOUT_SETTINGS_DEFAULT = {
  landing: {
    body: {
      background_type: LANDING_BACKGROUND_FLAT,
      background_color: '#FFF6ED',
      background_image: {
        desktop_url: '',
        mobile_url: ''
      },
      degraded_color: {
        degraded_1: '#FFF6ED',
        degraded_2: '#FFFFFF'
      }
    },
    buttons_icons: {
      button_color: '#22D380',
      text_color: '#FFFFFF',
      form: LANDING_FORM_FILLED_SQUARED,
      form_color: '#00000029'
    },
    content: {
      font: 'poppins',
      text_color: '#16171A'
    },
    footer: {
      app_download_color: '#22D380',
      background_color: '#16171A',
      download_app_enabled: false,
      olaclick_promotion_enabled: true,
      text_color: '#FFFFFF'
    },
    title: {
      name: '',
      summary: '',
      font: 'poppins',
      text_color: '#16171A'
    }
  },
  product: {
    background_color: BACKGROUND_COLOR,
    font_color: FONT_COLOR,
    primary_color: PRIMARY_COLOR,
    secondary_color: SECONDARY_COLOR
  }
}
