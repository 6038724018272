const COMPANY_DEFAULT_LOGO = {
  en: 'https://olaclick.s3.amazonaws.com/assets/logos/v2/logo-en.jpg',
  es: 'https://olaclick.s3.amazonaws.com/assets/logos/v2/logo-es.jpg',
  fr: 'https://olaclick.s3.amazonaws.com/assets/logos/v2/logo-fr.jpg',
  pt: 'https://olaclick.s3.amazonaws.com/assets/logos/v2/logo-pt.jpg',
  default: 'https://olaclick.s3.amazonaws.com/assets/logos/v2/logo-en.jpg'
}

const getDefaultCompanyLogo = (company) => {
  const language = company?.language || 'en'

  return COMPANY_DEFAULT_LOGO[language] || COMPANY_DEFAULT_LOGO.default
}

export default getDefaultCompanyLogo
