import {
  SNACKBAR_SUCCESS_ICON,
  SNACKBAR_TIMEOUT,
  SNACKBAR_INFO_ICON,
  SNACKBAR_ERROR_ICON,
  SNACKBAR_WARNING_ICON,
  SNACKBAR_DEFAULT_POSITION
} from '@/components/common/Notification/Snackbar/constants/snackbarDefaultProps.js'

import {
  REPLACE_SNACKBAR_TEXT,
  REPLACE_SNACKBAR_SHOW,
  REPLACE_SNACKBAR_COLOR,
  REPLACE_SNACKBAR_ICON,
  REPLACE_SNACKBAR_AUTO_SAVE,
  REPLACE_SNACKBAR_COPY,
  REPLACE_SNACKBAR_POSITION
} from '@/store/mutationTypes.js'

const state = () => ({
  show: false,
  autoSave: false,
  copy: false,
  text: 'success',
  color: '',
  position: SNACKBAR_DEFAULT_POSITION,
  icon: SNACKBAR_INFO_ICON,
  timeout: SNACKBAR_TIMEOUT
})

const getters = {}

const actions = {
  async showSnackbarSuccess ({ commit, dispatch }, { text, position = SNACKBAR_DEFAULT_POSITION }) {
    await dispatch('hideSnackbar')

    commit(REPLACE_SNACKBAR_TEXT, { text })
    commit(REPLACE_SNACKBAR_COLOR, { color: 'success' })
    commit(REPLACE_SNACKBAR_ICON, { icon: SNACKBAR_SUCCESS_ICON })
    commit(REPLACE_SNACKBAR_POSITION, { position })
    commit(REPLACE_SNACKBAR_SHOW, { show: true })
  },

  async showSnackbarInfo ({ commit, dispatch }, { text, position = SNACKBAR_DEFAULT_POSITION }) {
    await dispatch('hideSnackbar')

    commit(REPLACE_SNACKBAR_TEXT, { text })
    commit(REPLACE_SNACKBAR_COLOR, { color: 'info' })
    commit(REPLACE_SNACKBAR_ICON, { icon: SNACKBAR_INFO_ICON })
    commit(REPLACE_SNACKBAR_POSITION, { position })
    commit(REPLACE_SNACKBAR_SHOW, { show: true })
  },

  async showSnackbarError ({ commit, dispatch }, { text, position = SNACKBAR_DEFAULT_POSITION }) {
    await dispatch('hideSnackbar')

    commit(REPLACE_SNACKBAR_TEXT, { text })
    commit(REPLACE_SNACKBAR_COLOR, { color: 'error' })
    commit(REPLACE_SNACKBAR_ICON, { icon: SNACKBAR_ERROR_ICON })
    commit(REPLACE_SNACKBAR_POSITION, { position })
    commit(REPLACE_SNACKBAR_SHOW, { show: true })
  },

  async showSnackbarWarning ({ commit, dispatch }, { text, position = SNACKBAR_DEFAULT_POSITION }) {
    await dispatch('hideSnackbar')

    commit(REPLACE_SNACKBAR_TEXT, { text })
    commit(REPLACE_SNACKBAR_COLOR, { color: 'warning' })
    commit(REPLACE_SNACKBAR_ICON, { icon: SNACKBAR_WARNING_ICON })
    commit(REPLACE_SNACKBAR_POSITION, { position })
    commit(REPLACE_SNACKBAR_SHOW, { show: true })
  },
  async showSnackbarAutoSave ({ commit, dispatch }, { text, position = SNACKBAR_DEFAULT_POSITION } = {}) {
    await dispatch('hideSnackbar')

    commit(REPLACE_SNACKBAR_TEXT, { text })
    commit(REPLACE_SNACKBAR_AUTO_SAVE, { autoSave: true })
    commit(REPLACE_SNACKBAR_POSITION, { position })
    commit(REPLACE_SNACKBAR_SHOW, { show: true })
  },
  async showSnackbarCopy ({ commit, dispatch }, { text }) {
    await dispatch('hideSnackbar')

    commit(REPLACE_SNACKBAR_TEXT, { text })
    commit(REPLACE_SNACKBAR_COLOR, { color: '' })
    commit(REPLACE_SNACKBAR_COPY, { copy: true })
    commit(REPLACE_SNACKBAR_SHOW, { show: true })
  },

  hideSnackbar ({ commit }) {
    commit(REPLACE_SNACKBAR_SHOW, { show: false })
    commit(REPLACE_SNACKBAR_AUTO_SAVE, { autoSave: false })
    commit(REPLACE_SNACKBAR_COPY, { copy: false })
    commit(REPLACE_SNACKBAR_TEXT, { text: '' })
  }
}

const mutations = {
  [REPLACE_SNACKBAR_AUTO_SAVE] (state, { autoSave }) {
    state.autoSave = autoSave
  },

  [REPLACE_SNACKBAR_SHOW] (state, { show }) {
    state.show = show
  },

  [REPLACE_SNACKBAR_TEXT] (state, { text }) {
    state.text = text
  },

  [REPLACE_SNACKBAR_COLOR] (state, { color }) {
    state.color = color
  },

  [REPLACE_SNACKBAR_ICON] (state, { icon }) {
    state.icon = icon
  },

  [REPLACE_SNACKBAR_COPY] (state, { copy }) {
    state.copy = copy
  },

  [REPLACE_SNACKBAR_POSITION] (state, { position }) {
    state.position = position
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
