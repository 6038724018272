import app from '@/store/modules/app.js'
import auth from '@/store/modules/auth'
import axiosCancelTokens from '@/store/modules/axiosCancelTokens.js'
import chatbot from '@/store/modules/chatbot.js'
import companyBranches from '@/store/modules/companyBranches.js'
import companyLayoutSettings from '@/store/modules/companyLayoutSettings.js'
import companySettings from '@/store/modules/companySettings.js'
import defaultLayout from '@/store/modules/defaultLayout.js'
import downloads from '@/store/modules/downloads.js'
import featuresRestricted from '@/store/modules/featuresRestricted.js'
import flutter from '@/store/modules/flutter.js'
import introMessages from '@/store/modules/introMessages.js'
import mobilePreview from '@/store/modules/mobilePreview'
import snackbar from '@/store/modules/snackbar'
import subscription from '@/store/modules/subscription.js'
import subscriptions from '@/store/modules/subscriptions.js'
import userCompany from '@/store/modules/userCompany.js'
import ecommerceSettings from '@/store/modules/ecommerceSettings.js'
import webapp from '@/store/modules/webapp'
import notifications from '@/store/modules/notifications.js'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    auth,
    axiosCancelTokens,
    chatbot,
    companyBranches,
    companyLayoutSettings,
    companySettings,
    defaultLayout,
    downloads,
    featuresRestricted,
    flutter,
    introMessages,
    mobilePreview,
    snackbar,
    subscription,
    subscriptions,
    userCompany,
    webapp,
    notifications,
    ecommerceSettings
  }
})

export default store
