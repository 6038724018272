import getBrowserLanguage from '@/plugins/i18n/helpers/getBrowserLanguage.js'
import { COUNTRY_CODE_MEXICO } from '@/components/Company/constants/companyCountryCodes.js'
import { SET_PWA_INSTALL_PROMPT } from '@/store/mutationTypes.js'
import {
  LANGUAGE_ES_MX,
  LANGUAGE_ES,
  LANGUAGE_EN,
  SUPPORTED_LANGUAGES
} from '@/constants/languages.js'

const browserLanguage = getBrowserLanguage()

const getSupportedLanguage = (lang) => {
  const [langCode] = lang.split('-')
  if (SUPPORTED_LANGUAGES.includes(lang)) { return lang }
  if (SUPPORTED_LANGUAGES.includes(langCode)) { return langCode }
  return LANGUAGE_EN
}

const state = () => ({
  PWAInstallPrompt: null
})

const getters = {
  appLanguage: (state, getters, rootState, rootGetters) => {
    const userCompany = rootState.userCompany.userCompany
    const userCompanyLanguage = rootGetters['userCompany/userCompanyLanguage']
    const userCompanyCountryCode = rootGetters['userCompany/userCompanyCountryCode']

    if (!userCompany) {
      return getSupportedLanguage(browserLanguage)
    }

    if (userCompanyLanguage === LANGUAGE_ES) {
      return getSupportedLanguage(userCompanyCountryCode === COUNTRY_CODE_MEXICO ? LANGUAGE_ES_MX : LANGUAGE_ES)
    }

    return getSupportedLanguage(userCompanyLanguage)
  },
  appLanguageCode: (state, getters) => {
    const [languageCode] = getters.appLanguage.split('-')
    return languageCode
  }
}
const actions = {
  setPWAInstallPrompt ({ commit }, { PWAInstallPrompt }) {
    commit(SET_PWA_INSTALL_PROMPT, { PWAInstallPrompt })
  }
}

const mutations = {
  [SET_PWA_INSTALL_PROMPT] (state, { PWAInstallPrompt }) {
    state.PWAInstallPrompt = PWAInstallPrompt
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
