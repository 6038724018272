import env from '@/env.js'
import getAlovaInstanceWithAuth from '@/helpers/getAlovaInstanceWithAuth'

const BASE_URL = `${env('API_BASE_URL')}/ms-notifications`
const alovaInstance = getAlovaInstanceWithAuth(BASE_URL)

export default {
  getSettings: () => {
    return alovaInstance.Get('/auth/settings', {
      name: 'getSettings',
      hitSource: undefined
    })
  },
  patchChatbotSettings: (data, field) => {
    return alovaInstance.Patch('/auth/settings/chatbot_settings', data, {
      name: 'patchChatbotSettings'
    })
  }
}
