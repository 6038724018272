import {
  ADD_CANCEL_TOKEN,
  EMPTY_CANCEL_TOKENS
} from '@/store/mutationTypes.js'

const state = () => ({
  cancelTokens: []
})

const getters = {}

const actions = {
  addCancelToken ({ commit }, { source }) {
    commit(ADD_CANCEL_TOKEN, { source })
  },
  cancelPendingRequests ({ commit, state }) {
    // Cancel all request where a token exists
    state.cancelTokens.forEach((request, i) => {
      if (request.cancel) {
        request.cancel('Operation canceled by the user.')
      }
    })

    // Reset the cancelTokens state
    commit(EMPTY_CANCEL_TOKENS)
  }
}

const mutations = {
  [ADD_CANCEL_TOKEN] (state, { source }) {
    state.cancelTokens.push(source)
  },
  [EMPTY_CANCEL_TOKENS] (state) {
    state.cancelTokens = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
