import { get, post, put } from './base'
import env from '@/env.js'

export const API_ME_PATH = '/auth/me'
export const API_LOGIN_PATH = '/api/login'
export const API_RECOVER_PASSWORD_PATH = '/api/recover-password'

const redirectToAuthPage = (authBaseUrl) => {
  location.href = `${authBaseUrl}/?session_redirect=${encodeURIComponent(window.location.href)}`
}

export const Auth = {
  me: () => {
    return new Promise((resolve, reject) => {
      get(API_ME_PATH)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (env('AUTH_BASE_URL')) {
            redirectToAuthPage(env('AUTH_BASE_URL'))
            return
          }
          reject(error)
        })
    })
  },
  login: ({ data }) => post(API_LOGIN_PATH, data),
  logout: () => post('/api/logout'),
  autologin: ({ userId, params }) => get(`/api/autologin/${userId}`, { params }),
  recoverPassword: ({ data }) => post('/api/recover-password', data),
  resetPassword: ({ data }) => put('/api/reset-password', data),
  verifySessionToResetPassword: ({ params }) => get('/api/reset-password-session', { params }),
  checkAuthToken: () => get('/api/check-auth-token'),
  logoutCompany: (companyToken) => post('/api/logout-company', { company_token: companyToken })
}
