import { Company as CompanyAPI } from '@/api/company'
import { COMPANY_COUNTRY_NAME_BRAZIL } from '@/components/Company/constants/companyCountryNames.js'
import { PAYMENT_INTEGRATION_MERCADOPAGO_NAME } from '@/constants/paymentIntegrationNames.js'
import {
  SET_LOADING_USER_COMPANY, SET_USER_COMPANY, SET_USER_COMPANY_DATA
} from '@/store/mutationTypes.js'
import { SUBSCRIPTION_PLAN_DOMAIN, SUBSCRIPTION_PLAN_FREE } from '@/constants/subscriptionPlans.js'
import getCompanyLogoUrl from '@/helpers/getCompanyLogoUrl.js'
import { LANGUAGE_EN } from '@/constants/languages.js'
import companiesMS from '@/services/companiesMS'
import { useRequest } from 'alova'
import sendPostMessageToParent from '@/helpers/sendPostMessageToParent.js'

const state = () => ({
  userCompany: null,
  loadingUserCompany: false
})
const getters = {
  userCompanyId: (state, getters, rootState, rootGetters) => {
    if (!rootState.auth.user) return null
    return rootState.auth.user.company_id
  },
  userCompanyName: (state) => {
    if (!state.userCompany) return ''
    return state.userCompany.name
  },
  userCompanyEmail: (state) => {
    if (!state.userCompany) return ''
    return state.userCompany.email
  },
  userCompanyMenuType: (state) => {
    if (!state.userCompany) return null
    return state.userCompany.menu_type
  },
  userCompanyLanguage: (state) => {
    if (!state.userCompany) return LANGUAGE_EN
    return state.userCompany.language || LANGUAGE_EN
  },
  userCompanyDateFormat: (state, getters) => {
    return getters.userCompanyLanguage === LANGUAGE_EN ? 'yyyy/MM/dd' : 'dd/MM/yyyy'
  },
  userCompanyAddress: (state) => {
    if (!state.userCompany) return ''
    return state.userCompany.address || ''
  },
  userCompanyCurrencyData: (state) => {
    if (!state.userCompany) return null
    return state.userCompany.currency
  },
  userCompanyCurrencySymbol: (state) => {
    if (!state.userCompany) return ''
    return state.userCompany.currency?.symbol || ''
  },
  userCompanyToken: (state) => {
    return state.userCompany?.token || null
  },
  userCompanyPublicUrl: (state) => {
    return state.userCompany?.public_url || null
  },
  userCompanyCountryName: (state) => {
    if (!state.userCompany) return null
    return state.userCompany.country
  },
  userCompanyCountryCode: (state) => {
    if (!state.userCompany) return null
    return state.userCompany.country_data?.country_code
  },
  userCompanyCountryIsBrazil: (state, getters) => {
    const companyCountryName = getters.userCompanyCountryName
    return companyCountryName === COMPANY_COUNTRY_NAME_BRAZIL
  },
  userCompanyPaymentIntegrations: (state) => {
    if (!state.userCompany) return []
    return state.userCompany.payment_integrations
  },
  userCompanySubscriptions: (state) => {
    const activeSubscriptions = state.userCompany?.active_subscriptions || []
    if (activeSubscriptions.length > 0) return activeSubscriptions
    return [SUBSCRIPTION_PLAN_FREE]
  },
  userCompanySubscription: (state, getters) => {
    return getters.userCompanySubscriptions[0]
  },
  userCompanyPaymentIntegrationsIncludesMercadopago: (state, getters) => {
    const paymentIntegrations = getters.userCompanyPaymentIntegrations
    return !!paymentIntegrations.find(item => item.name === PAYMENT_INTEGRATION_MERCADOPAGO_NAME)
  },
  userCompanyLatitude: (state) => {
    if (!state.userCompany) return 0
    return state.userCompany.latitude
  },
  userCompanyLongitude: (state) => {
    if (!state.userCompany) return 0
    return state.userCompany.longitude
  },
  userCompanyTimezone: (state) => {
    if (!state.userCompany) return ''
    return state.userCompany.timezone
  },
  userCompanyAverageDeliveryTime: (state) => {
    if (!state.userCompany) return ''
    return state.userCompany.average_delivery_time || ''
  },
  userCompanyLogoUrl: (state, getters) => {
    const company = state.userCompany
    return getCompanyLogoUrl(company)
  },
  userCompanyCoordinates: (state) => {
    return {
      lat: state.userCompany?.latitude || null,
      lng: state.userCompany?.longitude || null
    }
  },
  userCompanyDomain: (state, getters) => {
    if (!state.userCompany) return null
    return state.userCompany?.domain_settings || null
  },
  companyHasDomainPlan: (state, getters) => {
    if (!state.userCompany) return false
    const domainPlanIsActive = getters.userCompanySubscriptions.includes(SUBSCRIPTION_PLAN_DOMAIN)

    return domainPlanIsActive || !!getters.userCompanyDomain
  },
  userCompanyBusinessHours: (state) => {
    if (!state.userCompany) return null
    return state.userCompany.business_hours_settings
  }
}

const {
  send: sendPatchBusinessHours
} = useRequest((data) => companiesMS.patchBusinessHours(data),
  { immediate: false })

const actions = {
  setUserCompany ({ commit }, { company }) {
    commit(SET_USER_COMPANY, { company })
  },
  setLoadingUserCompany ({ commit }, { loading }) {
    commit(SET_LOADING_USER_COMPANY, { loading })
  },
  updateUserCompany ({ commit, state, getters }, { data }) {
    const companyId = getters.userCompanyId

    return new Promise((resolve, reject) => {
      CompanyAPI.update({ companyId, data })
        .then((response) => {
          const company = response.data.data
          // updated params company
          company.country_data = company.country || null
          company.country = company?.country?.name || null
          // updated params company
          commit(SET_USER_COMPANY, { company })

          resolve(company)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  partialUpdateUserCompany ({ commit, state, getters }, { data }) {
    const companyId = getters.userCompanyId

    return new Promise((resolve, reject) => {
      CompanyAPI.partialUpdate({ companyId, data })
        .then((company) => {
          // updated params company
          company.country_data = company.country || null
          company.country = company?.country?.name || null
          // updated params company
          commit(SET_USER_COMPANY, { company })
          sendPostMessageToParent({
            type: 'POSTMESSAGE_TYPE_INVALIDATE_CACHE',
            data: 'getCompany'
          })
          resolve(company)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async patchBusinessHours ({ commit, state, getters }, data) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await sendPatchBusinessHours(data)
      sendPostMessageToParent({
        type: 'POSTMESSAGE_TYPE_INVALIDATE_CACHE',
        data: 'getCompany'
      })
      sendPostMessageToParent({
        type: 'POSTMESSAGE_TYPE_UPDATE_MOBILE_PREVIEW'
      })
      return response
    } catch (error) {
      throw error
    }
  },
  setUserCompanyData ({ commit }, { key, value }) {
    commit(SET_USER_COMPANY_DATA, { key, value })
  }
}

const mutations = {
  [SET_USER_COMPANY] (state, { company }) {
    state.userCompany = company
  },
  [SET_LOADING_USER_COMPANY] (state, { loading }) {
    state.loadingUserCompany = loading
  },

  [SET_USER_COMPANY_DATA] (state, { key, value }) {
    state.userCompany[key] = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
