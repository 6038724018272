export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'

export const SET_PWA_INSTALL_PROMPT = 'SET_PWA_INSTALL_PROMPT'
export const SHOW_APP_LOADING_OVERLAY = 'SHOW_APP_LOADING_OVERLAY'

export const UPDATE_OR_ADD_COMPANY_BRANCH = 'UPDATE_OR_ADD_COMPANY_BRANCH'
export const REMOVE_COMPANY_BRANCH = 'REMOVE_COMPANY_BRANCH'
export const EMPTY_COMPANY_BRANCHES = 'EMPTY_COMPANY_BRANCHES'
export const SET_COMPANY_BRANCH_TOKEN = 'SET_COMPANY_BRANCH_TOKEN'
export const SET_PARTIAL_COMPANY_BRANCH = 'SET_PARTIAL_COMPANY_BRANCH'

export const SET_DEFAULT_LAYOUT_LEFT_DRAWER_SHOW = 'SET_DEFAULT_LAYOUT_LEFT_DRAWER_SHOW'
export const SHOW_MODAL_TO_REPORT_EXPIRED_SESSION = 'SHOW_MODAL_TO_REPORT_EXPIRED_SESSION'
export const SHOW_MODAL_NOT_PERMISSION = 'SHOW_MODAL_NOT_PERMISSION'
export const SHOW_LOGOUT_MODAL = 'SHOW_LOGOUT_MODAL'

export const SET_PUSH_SUBSCRIPTION_ID = 'SET_PUSH_SUBSCRIPTION_ID'

export const SET_USER_COMPANY = 'SET_USER_COMPANY'
export const SET_LOADING_USER_COMPANY = 'SET_LOADING_USER_COMPANY'
export const SET_USER_COMPANY_DATA = 'SET_USER_COMPANY_DATA'
export const SET_USER_DATA = 'SET_USER_DATA'

export const SET_COMPANY_LIST = 'SET_COMPANY_LIST'
export const SET_LOADING_COMPANY_LIST = 'SET_LOADING_COMPANY_LIST'

export const SET_MOBILE_PREVIEW_SHOW = 'SET_MOBILE_PREVIEW_SHOW'
export const SET_INCREMENT_KEY_MOBILE_PREVIEW = 'SET_INCREMENT_KEY_MOBILE_PREVIEW'

export const ADD_CANCEL_TOKEN = 'ADD_CANCEL_TOKEN'
export const EMPTY_CANCEL_TOKENS = 'EMPTY_CANCEL_TOKENS'

export const SET_SHOW_TOUR_OVERLAY = 'SET_SHOW_TOUR_OVERLAY'
export const SET_SHOW_TOUR_CONTENT = 'SET_SHOW_TOUR_CONTENT'
export const SET_TOUR_CURRENT_STEP = 'SET_TOUR_CURRENT_STEP'

// snackbar
export const REPLACE_SNACKBAR_TEXT = 'REPLACE_SNACKBAR_TEXT'
export const REPLACE_SNACKBAR_SHOW = 'REPLACE_SNACKBAR_SHOW'
export const REPLACE_SNACKBAR_COLOR = 'REPLACE_SNACKBAR_COLOR'
export const REPLACE_SNACKBAR_ICON = 'REPLACE_SNACKBAR_ICON'
export const REPLACE_SNACKBAR_AUTO_SAVE = 'REPLACE_SNACKBAR_AUTO_SAVE'
export const REPLACE_SNACKBAR_COPY = 'REPLACE_SNACKBAR_COPY'
export const REPLACE_SNACKBAR_POSITION = 'REPLACE_SNACKBAR_POSITION'

export const SET_TIPS_OF_THE_NOTIFICATION_BY_SEGMENT = 'SET_TIPS_OF_THE_NOTIFICATION_BY_SEGMENT'

// chatbot
export const SET_CHATBOT_STATUS = 'SET_CHATBOT_STATUS'
export const SET_LOADING_CHATBOT_STATUS = 'SET_LOADING_CHATBOT_STATUS'

/* COMPANY SETTINGS */
export const SET_COMPANY_SETTINGS = 'SET_COMPANY_SETTINGS'
export const SET_COMPANY_SETTINGS_DATA = 'SET_COMPANY_SETTINGS_DATA'
export const SET_COMPANY_PRINT_SETTINGS_DATA = 'SET_COMPANY_PRINT_SETTINGS_DATA'
export const SET_COMPANY_LAYOUT_SETTINGS = 'SET_COMPANY_LAYOUT_SETTINGS'
export const SET_LAYOUT_SETTINGS_LANDING_DATA = 'SET_LAYOUT_SETTINGS_LANDING_DATA'
export const SET_LAYOUT_SETTINGS_PRODUCT_DATA = 'SET_LAYOUT_SETTINGS_PRODUCT_DATA'
export const SET_COMPANY_SETTINGS_SERVICE_TYPE_DATA = 'SET_COMPANY_SETTINGS_SERVICE_TYPE_DATA'

/* orders2 */
export const SET_SHOW_ORDER_TYPE_PAUSED_OPTIONS = 'SET_SHOW_ORDER_TYPE_PAUSED_OPTIONS'
// Intro messages
export const SET_PAGE_NAME_WHERE_THE_INTRODUCTORY_MESSAGES_ARE_DISPLAYED = 'SET_PAGE_NAME_WHERE_THE_INTRODUCTORY_MESSAGES_ARE_DISPLAYED'
// Riders
export const SET_ASSIGNED_RIDER = 'SET_ASSIGNED_RIDER'
export const SET_EXIST_ASSIGNED_RIDER = 'SET_EXIST_ASSIGNED_RIDER'
// Subscriptions
export const SET_SHOW_SUBSCRIPTION_PREMIUM_MODAL = 'SET_SHOW_SUBSCRIPTION_PREMIUM_MODAL'
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS'
export const SET_CURRENT_SUBSCRIPTIONS = 'SET_CURRENT_SUBSCRIPTIONS'
export const SET_PARTIAL_SUBSCRIPTION_DATA = 'SET_PARTIAL_SUBSCRIPTION_DATA'
export const SET_LOADING_SUBSCRIPTIONS = 'SET_LOADING_SUBSCRIPTIONS'
// Campaings
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS'
export const SET_LOADING_CAMPAIGNS = 'SET_LOADING_CAMPAIGNS'
export const SET_SENDING_CAMPAIGN = 'SET_SENDING_CAMPAIGN'

export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS'
export const SET_LOADING_PAYMENT_METHODS = 'SET_LOADING_PAYMENT_METHODS'

export const ADD_DOWNLOAD = 'ADD_DOWNLOAD'
export const UPDATE_DOWNLOAD = 'UPDATE_DOWNLOAD'

export const SET_SPECIAL_OFFER_IS_ACTIVE = 'SET_SPECIAL_OFFER_IS_ACTIVE'

export const SET_NOTIFICATIONS_SETTINGS = 'SET_NOTIFICATIONS_SETTINGS'
export const SET_SETTINGS_CHATBOT_DATA = 'SET_SETTINGS_CHATBOT_DATA'
