import {
  ADD_DOWNLOAD,
  UPDATE_DOWNLOAD
} from '@/store/mutationTypes.js'

const state = () => ({
  downloads: []
})

const getters = {

}

const actions = {
  addDownload ({ commit }, { download }) {
    commit(ADD_DOWNLOAD, { download })
  },
  updateDownload ({ commit }, { exportId, download }) {
    commit(UPDATE_DOWNLOAD, { exportId, download })
  }
}

const mutations = {
  [ADD_DOWNLOAD] (state, { download }) {
    state.downloads.push(download)
  },
  [UPDATE_DOWNLOAD] (state, { exportId, download }) {
    const oldDownload = state.downloads.find(item => item.exportId === exportId)

    if (!oldDownload) { return }

    for (const [key, value] of Object.entries(download)) {
      oldDownload[key] = value || oldDownload[key]
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
