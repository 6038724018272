<template>
  <div
    :style="appStyles"
  >
    <transition
      name="fade-layout"
      mode="out-in"
    >
      <component :is="$route.meta.layout || 'div'">
        <transition
          name="fade-router-view"
          mode="out-in"
        >
          <router-view />
        </transition>
      </component>
    </transition>

    <v-overlay
      color="#ffffff"
      opacity="1"
      :value="showAppLoadingOverlay"
    >
      <div class="app-loading-container">
        <img
          width="100"
          height="100"
          src="/app-loader-200x200.gif"
        >
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  el: '#app',
  computed: {
    ...mapState({
      showAppLoadingOverlay: (state) => state.defaultLayout.showAppLoadingOverlay
    }),
    ...mapGetters({
      appStyles: 'defaultLayout/appStyles'
    })
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
.app-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.fade-layout-enter-active,
.fade-layout-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-layout-enter,
.fade-layout-leave-active {
  opacity: 0
}

.fade-router-view-enter-active,
.fade-router-view-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-router-view-enter,
.fade-router-view-leave-active {
  opacity: 0
}
</style>
