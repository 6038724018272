import env from '@/env.js'
import { loadLanguageAsync } from '@/plugins/i18n/index.js'
import routes from '@/router/routes.js'
import store from '@/store/index.js'
import Segment from '@dansmaculotte/vue-segment'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            offset: { x: 0, y: 0 },
            behavior: 'smooth'
          })
        }, 2000)
      })
    }

    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const companyToken = to.query.u

  if (typeof companyToken !== 'string') {
    return alert('[ERROR]: can\'t fetch user')
  }
  store.dispatch('companyBranches/setCompanyBranchToken', { companyToken: companyToken })

  store.dispatch('axiosCancelTokens/cancelPendingRequests')

  if (!store.getters['auth/user']) {
    try {
      await store.dispatch('auth/fetchUser')
    } catch (error) {
      console.error(error)
    }
  }
  loadLanguageAsync(store.getters['app/appLanguage'])

  next()
})

Vue.use(Segment, {
  writeKey: env('SEGMENT_KEY'),
  disabled: !env('SEGMENT_KEY')
})

export default router
