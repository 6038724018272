import { SET_PAGE_NAME_WHERE_THE_INTRODUCTORY_MESSAGES_ARE_DISPLAYED } from '@/store/mutationTypes.js'

const LOCALSTORAGE_KEY_PAGE_NAME = 'displayedMessages'

const DEFAULT_PAGE_NAMES = '[]'

const getPageNamesWhereTheIntroductoryMessagesAreDisplayedLocally = () => {
  try {
    return JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY_PAGE_NAME) || DEFAULT_PAGE_NAMES)
  } catch (error) {
    return DEFAULT_PAGE_NAMES
  }
}

const state = () => ({
  pageNamesWhereTheIntroductoryMessagesAreDisplayed: getPageNamesWhereTheIntroductoryMessagesAreDisplayedLocally()
})

const actions = {
  setPageNameWhereTheIntroMessagesAreDisplayed ({ commit }, { pageName }) {
    const data = {
      pageName
    }
    commit(SET_PAGE_NAME_WHERE_THE_INTRODUCTORY_MESSAGES_ARE_DISPLAYED, data)
  }
}

const mutations = {
  [SET_PAGE_NAME_WHERE_THE_INTRODUCTORY_MESSAGES_ARE_DISPLAYED] (state, data) {
    state.pageNamesWhereTheIntroductoryMessagesAreDisplayed.push(data)
    try {
      window.localStorage.setItem(LOCALSTORAGE_KEY_PAGE_NAME, JSON.stringify(state.pageNamesWhereTheIntroductoryMessagesAreDisplayed))
    } catch (error) {}
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
