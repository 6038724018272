<template>
  <v-app>
    <v-main>
      <slot />
    </v-main>

    <Snackbar />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import getBrowserLanguage from '@/plugins/i18n/helpers/getBrowserLanguage'

export default {
  components: {
    Snackbar: () => import('@/components/common/Notification/Snackbar')
  },
  computed: {
    ...mapState({
      userCompany: (state) => state.userCompany.userCompany
    }),
    ...mapGetters({
      userHasCompanyRol: 'auth/userHasCompanyRol'
    })
  },
  created () {
  },
  mounted () {
    this.setCurrentLanguage()
  },
  methods: {
    setCurrentLanguage () {
      const browserLanguage = getBrowserLanguage() // es || en || pt || fr || etc...
      const language = this.userCompany?.language && this.userHasCompanyRol ? this.userCompany?.language : browserLanguage
      this.$vuetify.lang.current = language.substring(0, 2)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
