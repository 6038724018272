import {
  SET_CHATBOT_STATUS,
  SET_LOADING_CHATBOT_STATUS
} from '@/store/mutationTypes.js'
import { CHATBOT_STATUS_PENDING, CHATBOT_STATUS_CONNECTED } from '@/constants/chatbotStatus.js'
import getChatbotStatusPromise from '@/helpers/getChatbotStatusPromise'

const state = () => ({
  chatbotStatus: CHATBOT_STATUS_PENDING,
  loadingChatbotStatus: false
})

const getters = {
  chatbotIsConnected: (state) => {
    return state.chatbotStatus === CHATBOT_STATUS_CONNECTED
  }
}

const actions = {
  async getFirstChatbotStatus ({ commit, getters, rootGetters }) {
    try {
      commit(SET_LOADING_CHATBOT_STATUS, { loading: true })

      const params = { companyId: rootGetters['userCompany/userCompanyId'] }
      const getChatbotStatus = getChatbotStatusPromise()

      const { status } = await getChatbotStatus({ params })

      commit(SET_CHATBOT_STATUS, { status })
    } catch (error) {
      console.error(error)
    } finally {
      commit(SET_LOADING_CHATBOT_STATUS, { loading: false })
    }
  },
  setChatbotStatus ({ commit }, { status }) {
    commit(SET_CHATBOT_STATUS, { status })
  }
}

const mutations = {
  [SET_CHATBOT_STATUS] (state, { status }) {
    state.chatbotStatus = status
  },
  [SET_LOADING_CHATBOT_STATUS] (state, { loading }) {
    state.loadingChatbotStatus = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
