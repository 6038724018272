import {
  SET_COMPANY_SETTINGS,
  SET_COMPANY_SETTINGS_DATA,
  SET_COMPANY_PRINT_SETTINGS_DATA,
  SET_SHOW_ORDER_TYPE_PAUSED_OPTIONS,
  SET_COMPANY_SETTINGS_SERVICE_TYPE_DATA
} from '@/store/mutationTypes.js'
import companiesMS from '@/services/companiesMS'
import { useRequest } from 'alova'

import { CompanySettings as CompanySettingsAPI } from '@/api/companySettings'
import getAppRequestTag from '@/helpers/getAppRequestTag'

const state = () => ({
  ecommerceSettings: null,
  showOrderTypePausedOptions: false
})
const getters = {
  companySettingId: (state, getters) => {
    if (!state.ecommerceSettings) return ''
    return state.ecommerceSettings?.id
  },
  companySettingAnalyticsSettings: (state) => {
    if (!state.ecommerceSettings) return null
    return state.ecommerceSettings?.analytics_settings
  },
  companySettingSocialNetworksSettings: (state) => {
    if (!state.ecommerceSettings) return null
    return state.ecommerceSettings?.social_networks_settings || null
  },
  companySettingFacebookDomainValidation: (state) => {
    if (!state.ecommerceSettings) return null
    return state.ecommerceSettings?.facebook_domain_validation || null
  },
  userCompanyDisplayGoogleRatings: (state) => {
    if (!state.ecommerceSettings) return null
    return state.ecommerceSettings.google_settings.display_ratings
  },
  userCompanyGooglePlaceId: (state) => {
    if (!state.ecommerceSettings) return null
    return state.ecommerceSettings.google_settings.place_id || ''
  },
  userCompanyGoogleRatings: (state) => {
    if (!state.ecommerceSettings) return null
    return state.ecommerceSettings.google_settings.ratings_score
  },
  userCompanyGoogleRatingsCount: (state) => {
    if (!state.ecommerceSettings) return null
    return state.ecommerceSettings.google_settings.ratings_count
  },
  userCompanyScheduledOrdersEnabled: (state) => {
    if (!state.ecommerceSettings) return false
    const onsiteScheduledOrdersEnabled = state.ecommerceSettings.onsite.scheduled_orders_enabled
    const takeawayScheduledOrdersEnabled = state.ecommerceSettings.takeaway.scheduled_orders_enabled
    const deliveryScheduledOrdersEnabled = state.ecommerceSettings.delivery.scheduled_orders_enabled
    return onsiteScheduledOrdersEnabled || takeawayScheduledOrdersEnabled || deliveryScheduledOrdersEnabled
  }
}

const {
  send: sendGetEcommerceSettings
} = useRequest((tag) => companiesMS.getEcommerceSettings(tag),
  { immediate: false })

const actions = {
  setCompanySettings ({ commit }, { ecommerceSettings }) {
    commit(SET_COMPANY_SETTINGS, { ecommerceSettings })
  },
  async getEcommerceSettings ({ commit, dispatch, rootState, rootGetters }) {
    // eslint-disable-next-line no-useless-catch
    try {
      const requestTag = getAppRequestTag(rootState.companyBranches.companyBranchToken)
      const ecommerceSettings = await sendGetEcommerceSettings(requestTag)
      const layoutSettings = ecommerceSettings?.layout_settings

      dispatch('companyLayoutSettings/setCompanyLayoutSettings', { layoutSettings }, { root: true })
      commit(SET_COMPANY_SETTINGS, { ecommerceSettings })
    } catch (error) {
      throw error
    }
  },

  patchEcommerceSettings ({ commit, state, getters }, { data }) {
    return new Promise((resolve, reject) => {
      CompanySettingsAPI.patchEcommerceSettings({ data })
        .then((ecommerceSettings) => {
          commit(SET_COMPANY_SETTINGS, { ecommerceSettings })

          resolve(ecommerceSettings)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setCompanySettingsData ({ commit }, { key, value }) {
    commit(SET_COMPANY_SETTINGS_DATA, { key, value })
  },
  partialUpdateGoogleSettings ({ getters, commit }, { data }) {
    return new Promise((resolve, reject) => {
      CompanySettingsAPI.partialUpdateGoogleSettings({ data })
        .then((ecommerceSettings) => {
          commit(SET_COMPANY_SETTINGS, { ecommerceSettings })
          resolve(ecommerceSettings)
        })
        .catch((error) => { reject(error) })
    })
  },
  partialUpdateTipSettings ({ getters, commit }, { data }) {
    const companySettingId = getters.companySettingId
    return new Promise((resolve, reject) => {
      CompanySettingsAPI.partialUpdateTipSettings({ companySettingId, data })
        .then((ecommerceSettings) => {
          commit(SET_COMPANY_SETTINGS, { ecommerceSettings })
          resolve(ecommerceSettings)
        })
        .catch((error) => { reject(error) })
    })
  },
  partialUpdateInventorySettings ({ getters, commit }, data) {
    const companySettingId = getters.companySettingId
    return new Promise((resolve, reject) => {
      CompanySettingsAPI.partialUpdateInventorySettings(companySettingId, data)
        .then((ecommerceSettings) => {
          commit(SET_COMPANY_SETTINGS, { ecommerceSettings })
          resolve(ecommerceSettings)
        })
        .catch((error) => { reject(error) })
    })
  },
  async partialUpdateSocialNetworksSettings ({ getters, commit }, { socialNetwork, data }) {
    try {
      const ecommerceSettings = await CompanySettingsAPI.partialUpdateSocialNetworksSettings(socialNetwork, data)
      commit(SET_COMPANY_SETTINGS, { ecommerceSettings })
      return ecommerceSettings
    } catch (error) {
      throw new Error(error)
    }
  },
  setCompanyPrintSettingsData ({ commit }, { key, value }) {
    commit(SET_COMPANY_PRINT_SETTINGS_DATA, { key, value })
  },
  setShowOrderTypePausedOptions ({ commit }, show) {
    commit(SET_SHOW_ORDER_TYPE_PAUSED_OPTIONS, show)
  },
  setCompanySettingServiceTypeData ({ commit }, { serviceType, data }) {
    commit(SET_COMPANY_SETTINGS_SERVICE_TYPE_DATA, { serviceType, data })
  }
}

const mutations = {
  [SET_COMPANY_SETTINGS] (state, { ecommerceSettings }) {
    state.ecommerceSettings = ecommerceSettings
  },
  [SET_COMPANY_SETTINGS_DATA] (state, { key, value }) {
    state.ecommerceSettings[key] = value
  },
  [SET_COMPANY_PRINT_SETTINGS_DATA] (state, { key, value }) {
    state.ecommerceSettings.print_settings[key] = value
  },
  [SET_SHOW_ORDER_TYPE_PAUSED_OPTIONS] (state, show) {
    state.showOrderTypePausedOptions = show
  },
  [SET_COMPANY_SETTINGS_SERVICE_TYPE_DATA] (state, { serviceType, data }) {
    for (const [key, value] of Object.entries(data)) {
      state.ecommerceSettings.service_types_settings[serviceType][key] = value
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
