import {
  SET_SUBSCRIPTIONS, SET_CURRENT_SUBSCRIPTIONS,
  SET_LOADING_SUBSCRIPTIONS,
  SET_SPECIAL_OFFER_IS_ACTIVE
} from '@/store/mutationTypes.js'
import { Subscription as SubscriptionAPI } from '@/api/subscription.js'
import getFreePlanIsFinished from '@/helpers/getFreePlanIsFinished.js'
import { PLAN_ADVANCED, PLAN_ELITE, PLAN_ONBOARDING, PLAN_PREMIUM, PLAN_TYPE_DOMAINS, PLAN_TYPE_MAIN } from '@/constants/plans'
import { parseISO, differenceInCalendarDays } from 'date-fns'

const state = () => ({
  subscriptions: [],
  currentSubscriptions: [],
  loadingSubscriptions: false,
  freePlanIsFinished: false,
  specialOfferIsActive: false
})
const getters = {
  companyHasPurchasedDomainPlan: (state, getters, rootState, rootGetters) => {
    const subscriptionDomainIndex = state.subscriptions.findIndex(item => item.plan_type === PLAN_TYPE_DOMAINS)
    return subscriptionDomainIndex >= 0
  },
  companyCanClaimFreeDomainName: (state, getters, rootState, rootGetters) => {
    if (!getters.subscriptionToUpgrade) return false

    const subscriptionDomainIndex = state.subscriptions.findIndex(item => item.plan_type === PLAN_TYPE_DOMAINS)
    if (subscriptionDomainIndex >= 0) return false

    const currentPlanId = getters.subscriptionToUpgrade.plan_id
    const currentPlanPeriod = getters.subscriptionToUpgrade.plan_period.period

    if ([PLAN_ELITE, PLAN_PREMIUM, PLAN_ADVANCED].includes(currentPlanId)) {
      return currentPlanPeriod === 12
    }
    return false
  },
  subscriptionToUpgrade: (state, getters, rootState, rootGetters) => {
    const currentMainSubscriptions = state.currentSubscriptions.filter(item => item.plan_type === PLAN_TYPE_MAIN)
    if (currentMainSubscriptions.length === 1) return currentMainSubscriptions[0]

    const companyIsElite = rootGetters['featuresRestricted/companyHasElitePlan']
    const companyIsPremium = rootGetters['featuresRestricted/companyHasPremiumPlan']
    const companyIsAdvance = rootGetters['featuresRestricted/companyHasAdvancedPlan']
    const companyIsOnboarding = rootGetters['featuresRestricted/companyHasOnboardingPlan']

    if (companyIsElite) {
      return currentMainSubscriptions.find(item => item.plan_id === PLAN_ELITE)
    }
    if (companyIsPremium) {
      return currentMainSubscriptions.find(item => item.plan_id === PLAN_PREMIUM)
    }
    if (companyIsAdvance) {
      return currentMainSubscriptions.find(item => item.plan_id === PLAN_ADVANCED)
    }
    if (companyIsOnboarding) {
      return currentMainSubscriptions.find(item => item.plan_id === PLAN_ONBOARDING)
    }
    return null
  },
  subscriptionToUpgradeCreditAmount: (state, getters) => {
    if (!getters.subscriptionToUpgrade) return 0
    const nextBillingDate = parseISO(`${getters.subscriptionToUpgrade.next_billing_date}+00:00`)
    const days = differenceInCalendarDays(nextBillingDate, new Date())
    const dailyPrice = getters.subscriptionToUpgrade.daily_price ?? 0
    return Math.round((days * dailyPrice) * 100) / 100
  },
  subscriptionToUpgradeIsPremiumAnual: (state, getters) => {
    if (!getters.subscriptionToUpgrade) return false
    const planId = getters.subscriptionToUpgrade.plan_id
    const period = getters.subscriptionToUpgrade.plan_period.period
    return period === 12 && planId === PLAN_PREMIUM
  },
  subscriptionsActiveWithoutOnboarding: (state, getters) => {
    const currentMainSubscriptions = state.currentSubscriptions.filter(item => item.plan_type === PLAN_TYPE_MAIN)

    return currentMainSubscriptions.filter(item => item.plan_id !== PLAN_ONBOARDING)
  }
}
const actions = {
  async setSpecialOfferIsActive ({ commit }, { active }) {
    commit(SET_SPECIAL_OFFER_IS_ACTIVE, { active })
  },
  async getSubscriptions ({ commit, state, rootState, rootGetters }) {
    try {
      commit(SET_LOADING_SUBSCRIPTIONS, { loading: true })
      const response = await SubscriptionAPI.getAllSubscriptions({
        companyId: rootGetters['userCompany/userCompanyId']
      })
      const subscriptions = response.data.data
      commit(SET_SUBSCRIPTIONS, { subscriptions: subscriptions })

      state.freePlanIsFinished = getFreePlanIsFinished({
        activeSubscriptions: rootState.featuresRestricted.subscriptions,
        companyCreatedAt: rootGetters['userCompany/userCompanyCreatedAt']
      })

      return subscriptions
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      console.error(error)
    } finally {
      commit(SET_LOADING_SUBSCRIPTIONS, { loading: false })
    }
  },
  async getCurrentSubscriptions ({ commit, state, rootState, rootGetters }) {
    try {
      const response = await SubscriptionAPI.getCurrentSubscriptions({
        companyId: rootGetters['userCompany/userCompanyId']
      })
      const subscriptions = response.data.data
      commit(SET_CURRENT_SUBSCRIPTIONS, { subscriptions: subscriptions })

      return subscriptions
    } catch (error) {
      console.error(error)
    }
  }
}

const mutations = {
  [SET_SUBSCRIPTIONS] (state, { subscriptions }) {
    state.subscriptions = subscriptions
  },
  [SET_CURRENT_SUBSCRIPTIONS] (state, { subscriptions }) {
    state.currentSubscriptions = subscriptions
  },
  [SET_SPECIAL_OFFER_IS_ACTIVE] (state, { active }) {
    state.specialOfferIsActive = active
  },
  [SET_LOADING_SUBSCRIPTIONS] (state, { loading }) {
    state.loadingSubscriptions = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
