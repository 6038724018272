export const DISCOUNT_COUPONS = 'DISCOUNT COUPONS'
export const CREATE_MY_DESIGNS = 'CREATE MY DESIGNS'
export const FREE_ADVERTISING = 'FREE ADVERTISING'
export const MY_CLIENTS = 'MY CLIENTS'
export const KITCHEN_ORDERS = 'KITCHEN ORDERS'
export const DELIVERY_USER_LOGGED = 'DELIVERY USER LOGGED'
export const DELIVERY_USER_ASSIGNED = 'DELIVERY USER ASSIGNED'
export const DELIVERY_ROL_SELECTED = 'DELIVERY ROL SELECTED'
export const MASS_SENDING = 'MASS SENDING'
export const INVENTORY = 'INVENTORY'
export const AUTOMATIC_STOCK_ACTIVATED = 'AUTOMATIC_STOCK_ACTIVATED'
