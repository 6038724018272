import '@ungap/global-this'
import Vue from 'vue'
import router from '@/router/index.js'
import { i18n } from '@/plugins/i18n'
import store from './store'
import vuetify from '@/plugins/vuetify'

import '@/scss/vuetify-hacks.scss'
import 'animate.css'
import '@/plugins/vueClipboard'
import '@/plugins/sweetAlert2'
import '@/plugins/vueGoogleMaps'
import '@/plugins/vueTelInputVuetify/vueTelInputVuetify.js'
import '@/plugins/vueInputMask.js'
import '@/plugins/vueMeta.js'
import '@/scss/app.scss'
import busPlugin from '@/plugins/busPlugin.js'
import googleTagManagerTrackPlugin from '@/plugins/googleTagManagerTrackPlugin.js'

import App from './App.vue'

Vue.config.productionTip = false

// necessary to make sure to listen the beforeinstallprompt event
window.addEventListener('beforeinstallprompt', function (e) {
  e.preventDefault()
  store.dispatch('app/setPWAInstallPrompt', { PWAInstallPrompt: e })
})

Vue.use(busPlugin)

if (process.env.GTM_ID) {
  Vue.use(googleTagManagerTrackPlugin)
}

// eslint-disable-next-line no-new
new Vue({
  i18n,
  store,
  router,
  vuetify,
  ...App
})
