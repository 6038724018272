const getImageWebp = (imageUrl) => {
  if (!imageUrl) { return null }

  const { origin, pathname } = new URL(imageUrl)
  const pathnameSplitted = pathname.split('.')
  const ext = pathnameSplitted[pathnameSplitted.length - 1]

  if (ext === 'webp') {
    return `${origin}${pathname}`
  }

  pathnameSplitted[pathnameSplitted.length - 1] = 'webp'
  const newPathname = pathnameSplitted.join('.')

  return `${origin}${newPathname}`
}

export default getImageWebp
