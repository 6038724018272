import store from '@/store/index.js'
import chatbotMS from '@/services/chatbotMS.js'

const getChatbotStatusPromise = () => {
  if (store.getters['featuresRestricted/companyHasPremiumPlan']) {
    return store.getters['userCompany/userCompanyCountryIsBrazil'] ? chatbotMS.getChatbotBRStatus : chatbotMS.getChatbotStatus
  }
  return chatbotMS.getChatbotFreeStatus
}

export default getChatbotStatusPromise
