import {
  FETCH_USER_SUCCESS,
  SET_USER_DATA

} from '@/store/mutationTypes.js'
import {
  ROLE_COMPANY_ADMIN,
  ROLE_OLACLICK_MANAGER,
  ROLE_OLACLICK_ADMIN,
  ROLE_COMPANY_MANAGER,
  ROLE_COMPANY_WAITER,
  ROLE_COMPANY_RIDER
} from '@/constants/userRoles.js'
import { COMPANY_BRANCH_STATUS_LOGGED_OUT } from '@/constants/companyBranchStatus.js'
import companiesMS from '@/services/companiesMS'
import { useRequest } from 'alova'
import getAppRequestTag from '@/helpers/getAppRequestTag'

const state = () => ({
  user: null
})

const getters = {
  authToken: (state, getters, rootState, rootGetters) => {
    const companyBranch = rootGetters['companyBranches/companyBranch']
    return companyBranch?.authToken || null
  },
  authTokenExpiration: (state, getters, rootState, rootGetters) => {
    const companyBranch = rootGetters['companyBranches/companyBranch']
    return companyBranch?.authTokenExpiration || null
  },
  userIsAuthenticated: (state) => {
    return !!state.user
  },
  userId: (state, getters) => {
    return state.user?.id || null
  },
  userEmail: (state, getters) => {
    return state.user?.email || null
  },
  userRole: (state, getters, rootState, rootGetters) => {
    return state.user?.role || rootGetters['companyBranches/companyBranchUserRole'] || null
  },
  userHasOlaClickRol: (state, getters) => {
    const rolesClient = [ROLE_OLACLICK_MANAGER, ROLE_OLACLICK_ADMIN]
    return rolesClient.includes(getters.userRole)
  },
  userHasCompanyRol: (state, getters) => {
    const rolesClient = [ROLE_COMPANY_ADMIN, ROLE_COMPANY_MANAGER, ROLE_COMPANY_WAITER, ROLE_COMPANY_RIDER]
    return rolesClient.includes(getters.userRole)
  },
  userRoleIsOlaClickAdmin: (state, getters) => {
    return getters.userRole === ROLE_OLACLICK_ADMIN
  },
  userRoleIsCompanyAdmin: (state, getters) => {
    return getters.userRole === ROLE_COMPANY_ADMIN
  },
  userRoleIsCompanyManager: (state, getters) => {
    return getters.userRole === ROLE_COMPANY_MANAGER
  },
  userRoleIsCompanyWaiter: (state, getters) => {
    return getters.userRole === ROLE_COMPANY_WAITER
  },
  userRoleIsCompanyRider: (state, getters) => {
    return getters.userRole === ROLE_COMPANY_RIDER
  }
}

const {
  send: sendMe
} = useRequest((tag) => companiesMS.me(tag),
  { immediate: false })
const {
  send: sendGetCompany
} = useRequest((companyId, tag) => companiesMS.getCompany(companyId, tag),
  { immediate: false })

const actions = {
  async fetchUser ({ commit, dispatch, state, getters, rootGetters, rootState }) {
    dispatch('userCompany/setLoadingUserCompany', { loading: true }, { root: true })
    // eslint-disable-next-line no-useless-catch
    try {
      const requestTag = getAppRequestTag(rootState.companyBranches.companyBranchToken)
      const user = await sendMe(requestTag)
      const company = await sendGetCompany(user.company_id, requestTag)
      company.country_data = company.country || null
      company.country = company?.country?.name || null
      const subscriptions = company.active_subscriptions || []

      commit(FETCH_USER_SUCCESS, { user })
      dispatch('userCompany/setUserCompany', { company }, { root: true })
      dispatch('featuresRestricted/setSubscriptions', { subscriptions }, { root: true })

      return user
    } catch (error) {
      dispatch('companyBranches/setPartialCompanyBranch', {
        companyToken: rootState.companyBranches.companyBranchToken,
        data: { status: COMPANY_BRANCH_STATUS_LOGGED_OUT }
      }, { root: true })
      dispatch('companyBranches/setCompanyBranchToken', { companyToken: null }, { root: true })
      throw error
    } finally {
      dispatch('userCompany/setLoadingUserCompany', { loading: false }, { root: true })
    }
  },
  setUserData ({ commit }, { key, value }) {
    commit(SET_USER_DATA, { key, value })
  }
}

const mutations = {
  [FETCH_USER_SUCCESS] (state, { user }) {
    state.user = user
  },
  [SET_USER_DATA] (state, { key, value }) {
    state.user[key] = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
