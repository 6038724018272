export const CHATBOT_MESSAGE_CONTENT_TYPE_RECOVER_CLIENT = 'recover_client'
export const CHATBOT_MESSAGE_CONTENT_TYPE_REVIVE_CLIENT = 'revive_client'
export const CHATBOT_MESSAGE_CONTENT_TYPE_CONVERT_IN_CLIENT = 'convert_in_client'

export const CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_PENDING = 'order_pending'
export const CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_ACCEPTED = 'order_preparing'
export const CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_READY_FOR_DELIVERY = 'order_ready'
export const CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_DRIVER_ARRIVED_AT_DESTINATION = 'order_driver_arrived_at_destination'
export const CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_DELIVERY_ON_THE_WAY = 'order_driver_on_the_way_to_destination'
export const CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_DELIVERED = 'order_delivered'
export const CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_COMPLETED = 'order_finalized'
export const CHATBOT_MESSAGE_CONTENT_TYPE_ORDER_CANCELLED = 'order_cancelled'

export const CHATBOT_MESSAGE_CONTENT_TYPE_GREETING_OPEN = 'greeting_open'
export const CHATBOT_MESSAGE_CONTENT_TYPE_GREETING_CLOSED_SCHEDULED_ORDER = 'greeting_closed_scheduled_order'
export const CHATBOT_MESSAGE_CONTENT_TYPE_GREETING_CLOSED_NO_SCHEDULED_ORDER = 'greeting_closed_no_scheduled_order'

export const CHATBOT_MESSAGE_CONTENT_TYPE_BUSINESS_HOURS = 'business_hours'
export const CHATBOT_MESSAGE_CONTENT_TYPE_INFORMATION = 'information'
export const CHATBOT_MESSAGE_CONTENT_TYPE_PROMOTION = 'promotion'
export const CHATBOT_MESSAGE_CONTENT_TYPE_RATING = 'rating'
export const CHATBOT_MESSAGE_CONTENT_TYPE_PLACE_AN_ORDER = 'place_an_order'
