import { get, patch, post } from './base'

export const Subscription = {
  getAllSubscriptions: ({ companyId, params }) => get(`/auth/companies/${companyId}/subscriptions`, { params }),
  getCurrentSubscriptions: ({ companyId }) => get(`/auth/companies/${companyId}/current-subscriptions`),
  getPaymentsOfSubscription: ({ subscriptionId, params }) => get(`/auth/subscriptions/${subscriptionId}/payments`, { params }),
  cancelSubscription: ({ subscriptionId, data }) => patch(`/auth/subscriptions/${subscriptionId}/cancel`, data),
  renewSubscription: ({ subscriptionId, data }) => post(`/auth/subscriptions/${subscriptionId}/renew`, data),
  downgrade: ({ subscriptionId, data }) => post(`/auth/subscriptions/${subscriptionId}/downgrade`, data),
  store: ({ companyId, data }) => post(`/auth/companies/${companyId}/subscriptions`, data)
}
