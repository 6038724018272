const getAppRequestTag = (companyToken) => {
  const appUrlKey = getAppUrlKey()
  return `${companyToken}_v${appUrlKey}`
}

const getAppUrlKey = () => {
  const searchParams = new URLSearchParams(window.location.search)
  if (!searchParams.has('key')) { return 0 }
  return searchParams.get('key')
}

export default getAppRequestTag
