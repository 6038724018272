import getDefaultCompanyLogo from '@/components/Company/helpers/getDefaultCompanyLogo.js'
import getImageWebp from '@/components/Product/helpers/getImageWebp.js'

const getCompanyLogoUrl = (company) => {
  const logoUrl = company?.logo_url || null

  if (!logoUrl) {
    return getDefaultCompanyLogo(company)
  }

  return getImageWebp(logoUrl)
}

export default getCompanyLogoUrl
