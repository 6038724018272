/* eslint-disable no-useless-catch */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import store from '@/store/index.js'
import { FALLBACK_LANGUAGES_MAP } from '@/constants/languages.js'

const loadedLanguages = [] // our default language that is preloaded

const getFallbackLocale = () => {
  return Object.entries(FALLBACK_LANGUAGES_MAP).reduce((fallbackLocale, [key, value]) => {
    return {
      ...fallbackLocale,
      [key]: [value]
    }
  }, {})
}

Vue.use(VueI18n)

export const i18n = new VueI18n({
  silentTranslationWarn: true,
  fallbackLocale: getFallbackLocale()
})

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  store.dispatch('defaultLayout/setShowAppLoadingOverlay', { show: false })
}

const loadLanguage = async (lang) => {
  try {
    const messages = await import(/* webpackChunkName: "lang-[request]" */ `@/plugins/i18n/translations/${lang}.js`)
    i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)
    return lang
  } catch (error) {
    throw error
  }
}

export const loadLanguageAsync = async (lang) => {
  try {
    // load fallback language if is necessary
    const fallbackLocale = FALLBACK_LANGUAGES_MAP[lang] || null
    if (!!fallbackLocale && !loadedLanguages.includes(fallbackLocale)) {
      loadLanguage(fallbackLocale)
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
      setI18nLanguage(lang)
      return lang
    }

    // If the language hasn't been loaded yet
    await loadLanguage(lang)
    setI18nLanguage(lang)
    return lang
  } catch (error) {
    throw error
  }
}
