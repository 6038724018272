const getBrowserLanguage = () => {
  // browserLanguage = es-PE || en-US || etc...
  const browserLanguage =
    (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
    navigator.language || // All browsers
    navigator.userLanguage || // IE <= 10
    'en'

  return browserLanguage
}

export default getBrowserLanguage
