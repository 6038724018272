import { createAlova } from 'alova'
import VueHook from 'alova/vue'
import GlobalFetch from 'alova/GlobalFetch'
import store from '@/store/index.js'
// import { VueOptionsHook } from '@alova/vue-options';

const getAlovaInstanceWithAuth = (baseURL) => {
  return createAlova({
    baseURL,
    statesHook: VueHook,
    requestAdapter: GlobalFetch(),
    beforeRequest (method) {
      const hasHeadersAdditional = method.config.headers
      const companyToken = store.getters['userCompany/userCompanyToken']
      const companyBranchToken = store.state.companyBranches.companyBranchToken

      method.config.credentials = 'include'
      method.config.headers = {
        accept: 'application/json,multipart/form-data',
        'app-company-token': companyBranchToken || companyToken,
        ...hasHeadersAdditional
      }
    },
    responded: {
      onSuccess: async (response) => {
        // 204 deletion successfull
        if ([204].includes(response.status)) {
          return
        }

        const json = await response.json()

        // 200 successful
        // 201 creation successful
        if ([200, 201].includes(response.status)) {
          return json?.data ?? json
        }

        throw new Error(json.message)
      },
      onError: (err) => {
        throw new Error(err.message)
      }
    }
  })
}

export default getAlovaInstanceWithAuth
