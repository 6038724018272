import { differenceInYears, parseISO } from 'date-fns'

const getFreePlanIsFinished = ({
  activeSubscriptions,
  companyCreatedAt
}) => {
  const maxYears = 1

  return activeSubscriptions.length > 0
    ? false
    : differenceInYears(new Date(), parseISO(companyCreatedAt)) >= maxYears
}

export default getFreePlanIsFinished
