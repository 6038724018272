import getAlovaInstanceWithAuth from '@/helpers/getAlovaInstanceWithAuth'
import env from '@/env.js'
import { add } from 'date-fns'

const baseURL = `${env('API_BASE_URL')}/ms-companies`
const alovaInstance = getAlovaInstanceWithAuth(baseURL)

export default {
  me: (tag) => {
    return alovaInstance.Get('/auth/me', {
      name: 'me',
      hitSource: ['updateUser', 'patchCompany'],
      localCache: {
        mode: 'restore',
        expire: add(new Date(), { minutes: 1 }),
        // Add or modify the tag parameter, the cached data will be invalid
        tag
      }
    })
  },
  getCompany: (companyId, tag) => {
    return alovaInstance.Get(`/auth/companies/${companyId}`, {
      name: 'getCompany',
      hitSource: ['patchBusinessHours', 'updateUser', 'patchCompany'],
      localCache: {
        mode: 'restore',
        expire: add(new Date(), { minutes: 1 }),
        // Add or modify the tag parameter, the cached data will be invalid
        tag
      }
    })
  },
  getEcommerceSettings: (tag) => {
    return alovaInstance.Get('/auth/ecommerce-settings', {
      name: 'getEcommerceSettings',
      localCache: {
        mode: 'restore',
        expire: add(new Date(), { minutes: 1 }),
        // Add or modify the tag parameter, the cached data will be invalid
        tag
      }
    })
  },
  patchCompany: (companyId, data) => {
    return alovaInstance.Patch(`/auth/companies/${companyId}`, data, {
      name: 'patchCompany'
    })
  },
  updateUser: (userId, data) => {
    return alovaInstance.Put(`/auth/companies/users/${userId}`, data, {
      name: 'updateUser'
    })
  },
  patchBusinessHours: (data) => {
    return alovaInstance.Patch('/auth/business-hours-settings', data, {
      name: 'patchBusinessHours'
    })
  }
}
