export default function env (property) {
  if (!process.env[property]) {
    console.info(`INFO: ${property} env var don't exists in frontend process.env`)
    return undefined
  }

  if (process.env[property] === 'true') {
    return true
  }

  if (process.env[property] === 'false') {
    return false
  }

  return process.env[property]
}
