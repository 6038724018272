export const SUBSCRIPTION_PLAN_ELITE = 'elite'
export const SUBSCRIPTION_PLAN_ONBOARDING = 'onboarding'
export const SUBSCRIPTION_PLAN_FREE = 'free'
export const SUBSCRIPTION_PLAN_ADVANCED = 'advanced'
export const SUBSCRIPTION_PLAN_PREMIUM = 'premium'
export const SUBSCRIPTION_PLAN_DOMAIN = 'domain'

export const PLAN_PRIORITIES = {
  [SUBSCRIPTION_PLAN_ELITE]: 5,
  [SUBSCRIPTION_PLAN_PREMIUM]: 4,
  [SUBSCRIPTION_PLAN_ADVANCED]: 3,
  [SUBSCRIPTION_PLAN_ONBOARDING]: 2,
  [SUBSCRIPTION_PLAN_FREE]: 1
}
