import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import '@/plugins/sweetAlert2/sweetalert2.scss'
import colors from 'vuetify/lib/util/colors'

const options = {
  confirmButtonColor: '#0074FF',
  cancelButtonColor: colors.red
}

Vue.use(VueSweetalert2, options)
