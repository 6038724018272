import {
  SET_COMPANY_SETTINGS,
  SET_COMPANY_SETTINGS_DATA,
  SET_COMPANY_PRINT_SETTINGS_DATA,
  SET_SHOW_ORDER_TYPE_PAUSED_OPTIONS,
  SET_COMPANY_SETTINGS_SERVICE_TYPE_DATA
} from '@/store/mutationTypes.js'

import { CompanySettings as CompanySettingsAPI } from '@/api/companySettings'

const state = () => ({
  companySettings: null,
  showOrderTypePausedOptions: false
})
const getters = {
  companySettingId: (state, getters) => {
    if (!state.companySettings) return ''
    return state.companySettings?.id
  },
  companySettingDomain: (state, getters) => {
    if (!state.companySettings) return null
    return state.companySettings?.domain || null
  },
  companySettingPaymentMethods: (state, getters, rootState) => {
    if (!state.companySettings) return []
    const paymentMethods = rootState?.paymentMethods?.paymentMethods || []
    return state.companySettings.payment_methods.map((methodSelected) => {
      const paymentMethod = paymentMethods.find(item => methodSelected.id === item.id)
      return {
        ...paymentMethod,
        ...methodSelected
      }
    })
  }
}

const actions = {
  setCompanySettings ({ commit }, { companySettings }) {
    commit(SET_COMPANY_SETTINGS, { companySettings })
  },
  partialUpdateCompanySettings ({ commit, state, getters }, { data }) {
    return new Promise((resolve, reject) => {
      CompanySettingsAPI.partialUpdate({ data })
        .then((companySettings) => {
          commit(SET_COMPANY_SETTINGS, { companySettings })

          resolve(companySettings)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setCompanySettingsData ({ commit }, { key, value }) {
    commit(SET_COMPANY_SETTINGS_DATA, { key, value })
  },
  partialUpdateServiceTypesSettings  ({ getters, commit }, { data }) {
    const companySettingId = getters.companySettingId
    return new Promise((resolve, reject) => {
      CompanySettingsAPI.partialUpdateServiceTypesSettings(companySettingId, data)
        .then((companySettings) => {
          commit(SET_COMPANY_SETTINGS, { companySettings })
          resolve(companySettings)
        })
        .catch((error) => { reject(error) })
    })
  },
  partialUpdateTipSettings  ({ getters, commit }, { data }) {
    const companySettingId = getters.companySettingId
    return new Promise((resolve, reject) => {
      CompanySettingsAPI.partialUpdateTipSettings({ companySettingId, data })
        .then((companySettings) => {
          commit(SET_COMPANY_SETTINGS, { companySettings })
          resolve(companySettings)
        })
        .catch((error) => { reject(error) })
    })
  },
  partialUpdateInventorySettings  ({ getters, commit }, data) {
    const companySettingId = getters.companySettingId
    return new Promise((resolve, reject) => {
      CompanySettingsAPI.partialUpdateInventorySettings(companySettingId, data)
        .then((companySettings) => {
          commit(SET_COMPANY_SETTINGS, { companySettings })
          resolve(companySettings)
        })
        .catch((error) => { reject(error) })
    })
  },
  setCompanyPrintSettingsData ({ commit }, { key, value }) {
    commit(SET_COMPANY_PRINT_SETTINGS_DATA, { key, value })
  },
  setShowOrderTypePausedOptions ({ commit }, show) {
    commit(SET_SHOW_ORDER_TYPE_PAUSED_OPTIONS, show)
  },
  setCompanySettingServiceTypeData ({ commit }, { serviceType, data }) {
    commit(SET_COMPANY_SETTINGS_SERVICE_TYPE_DATA, { serviceType, data })
  }
}

const mutations = {
  [SET_COMPANY_SETTINGS] (state, { companySettings }) {
    state.companySettings = companySettings
  },
  [SET_COMPANY_SETTINGS_DATA] (state, { key, value }) {
    state.companySettings[key] = value
  },
  [SET_COMPANY_PRINT_SETTINGS_DATA] (state, { key, value }) {
    state.companySettings.print_settings[key] = value
  },
  [SET_SHOW_ORDER_TYPE_PAUSED_OPTIONS] (state, show) {
    state.showOrderTypePausedOptions = show
  },
  [SET_COMPANY_SETTINGS_SERVICE_TYPE_DATA] (state, { serviceType, data }) {
    for (const [key, value] of Object.entries(data)) {
      state.companySettings.service_types_settings[serviceType][key] = value
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
