import { patch } from './base'
import { invalidateCache } from 'alova'
import sendPostMessageToParent from '@/helpers/sendPostMessageToParent.js'

export const CompanySettings = {
  partialUpdate: async ({ data }) => {
    try {
      const response = await patch('/auth/company-settings', data)
      invalidateCache()
      return response.data.data
    } catch (error) {
      throw new Error(error)
    }
  },
  partialUpdateTipSettings: async ({ companySettingId, data }) => {
    try {
      const response = await patch(`/auth/company-settings/${companySettingId}/tip-settings`, data)
      invalidateCache()
      return response.data.data
    } catch (error) {
      throw new Error(error)
    }
  },
  partialUpdateServiceTypesSettings: async (companySettingId, data) => {
    try {
      const response = await patch(`/auth/company-settings/${companySettingId}/service-types-settings`, data)
      invalidateCache()
      return response.data.data
    } catch (error) {
      throw new Error(error)
    }
  },
  partialUpdateInventorySettings: async (companySettingId, data) => {
    try {
      const response = await patch(`/auth/company-settings/${companySettingId}/inventory-settings`, data)
      invalidateCache()
      return response.data.data
    } catch (error) {
      throw new Error(error)
    }
  },
  partialUpdateAnalyticsSettings: async (companySettingId, data) => {
    try {
      const response = await patch('auth/ecommerce-settings/analytics-settings', data)
      invalidateCache()
      sendPostMessageToParent({
        type: 'POSTMESSAGE_TYPE_INVALIDATE_CACHE',
        data: 'getEcommerceSettings'
      })
      return response.data.data
    } catch (error) {
      throw new Error(error)
    }
  },
  partialUpdateSocialNetworksSettings: async (socialNetwork, data) => {
    try {
      const response = await patch(`/auth/ecommerce-settings/social-network-settings/${socialNetwork}`, data)
      invalidateCache()
      sendPostMessageToParent({
        type: 'POSTMESSAGE_TYPE_INVALIDATE_CACHE',
        data: 'getEcommerceSettings'
      })
      return response?.data?.data
    } catch (error) {
      throw new Error(error)
    }
  },
  partialUpdateGoogleSettings: async ({ data }) => {
    try {
      const response = await patch('/auth/ecommerce-settings/google-settings', data)
      invalidateCache()
      return response?.data?.data
    } catch (error) {
      throw new Error(error)
    }
  },
  patchEcommerceSettings: async ({ data }) => {
    try {
      const response = await patch('/auth/ecommerce-settings', data)
      invalidateCache()
      sendPostMessageToParent({
        type: 'POSTMESSAGE_TYPE_INVALIDATE_CACHE',
        data: 'getEcommerceSettings'
      })
      return response?.data?.data
    } catch (error) {
      throw new Error(error)
    }
  },
  // updateDomain: async (companySettingId, data) => {
  //   try {
  //     const response = await patch(`/auth/company-settings/${companySettingId}/domain`, data)
  //    return response?.data?.data
  //   } catch (error) {
  //     throw new Error(error)
  //   }
  // },
  partialUpdateLayoutSettings: async ({ companySettingId, data }) => {
    try {
      const response = await patch('/auth/ecommerce-settings/layout-settings', data)
      invalidateCache()
      return response?.data?.data
    } catch (error) {
      throw new Error(error)
    }
  },
  updateBusinessHours: async ({ companySettingId, data }) => {
    try {
      const response = await patch('/auth/ecommerce-settings/business-hours-settings', data)
      invalidateCache()
      return response.data.data
    } catch (error) {
      throw new Error(error)
    }
  }
}
