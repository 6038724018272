import {
  SET_MOBILE_PREVIEW_SHOW,
  SET_INCREMENT_KEY_MOBILE_PREVIEW
} from '@/store/mutationTypes.js'

const state = () => ({
  keyMobilePreview: 0,
  showMobilePreview: false
})
const getters = {
}

const actions = {
  setMobilePreviewShow ({ commit }, { show }) {
    commit(SET_MOBILE_PREVIEW_SHOW, { show })
  },
  incrementKeyMobilePreview ({ commit }) {
    commit(SET_INCREMENT_KEY_MOBILE_PREVIEW)
  }
}

const mutations = {
  [SET_MOBILE_PREVIEW_SHOW] (state, { show }) {
    state.showMobilePreview = show
  },
  [SET_INCREMENT_KEY_MOBILE_PREVIEW] (state) {
    state.keyMobilePreview += 1
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
