import { get, post } from '@/api/base.js'
import env from '@/env.js'

const MS_BASE_BR_URL = `${env('API_BASE_URL')}/ms-chatbots-br`
const MS_BASE_URL = `${env('API_BASE_URL')}/ms-chatbots-default`
const MS_BASE_FREE_URL = `${env('API_BASE_URL')}/ms-chatbots-free`

const ChatbotMS = {
  connectChatbotFree: async ({ data }) => {
    try {
      const response = await post(`${MS_BASE_FREE_URL}/auth/sessions/connect`, data)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },
  connectChatbot: async ({ data }) => {
    try {
      const response = await post(`${MS_BASE_URL}/auth/sessions/connect`, data)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },
  connectChatbotBR: async ({ data }) => {
    try {
      const response = await post(`${MS_BASE_BR_URL}/auth/sessions/connect`, data)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },
  disconnectChatbotFree: async ({ data }) => {
    try {
      const response = await post(`${MS_BASE_FREE_URL}/auth/sessions/disconnect`, data)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },
  disconnectChatbot: async ({ data }) => {
    try {
      const response = await post(`${MS_BASE_URL}/auth/sessions/disconnect`, data)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },
  disconnectChatbotBR: async ({ data }) => {
    try {
      const response = await post(`${MS_BASE_BR_URL}/auth/sessions/disconnect`, data)
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },
  getChatbotFreeStatus: async ({ params }) => {
    try {
      const response = await get(`${MS_BASE_FREE_URL}/auth/sessions/status`, { params })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },
  getChatbotStatus: async ({ params }) => {
    try {
      const response = await get(`${MS_BASE_URL}/auth/sessions/status`, { params })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  },
  getChatbotBRStatus: async ({ params }) => {
    try {
      const response = await get(`${MS_BASE_BR_URL}/auth/sessions/status`, { params })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }
}

export default ChatbotMS
