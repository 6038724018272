const getErrorsHTML = (errors = {}) => {
  let stringErrors = ''
  for (const [field, arrayErrors] of Object.entries(errors)) {
    stringErrors += `${field}: ${arrayErrors[0]}\n`
  }
  return stringErrors
}

const getErrorHTMLForSnackbar = (message = '', errors = {}) => {
  let htmlString = `${message}\n`
  htmlString += `${getErrorsHTML(errors)}`
  return htmlString
}

export default getErrorHTMLForSnackbar
