/* eslint-disable no-empty */
import axios from 'axios'
import getErrorHTMLForSnackbar from '@/components/common/Notification/Snackbar/helpers/getErrorHTMLForSnackbar.js'
import store from '@/store'
import ky from 'ky-universal'
import getTokenNeedsToBeUpdated from '@/api/helpers/getTokenNeedsToBeUpdated.js'
import {
  API_ME_PATH,
  API_LOGIN_PATH, API_RECOVER_PASSWORD_PATH
} from '@/api/auth.js'
import env from '@/env.js'
import {
  COMPANY_BRANCH_STATUS_ACTIVE,
  COMPANY_BRANCH_STATUS_LOGGED_OUT
} from '@/constants/companyBranchStatus.js'

let refreshingToken = false

const apiClient = axios.create({ baseURL: `${env('API_BASE_URL')}/ms-companies` })

apiClient.interceptors.request.use((config) => {
  const authToken = store.getters['auth/authToken']
  const companyToken = store.getters['userCompany/userCompanyToken']
  const companyBranchToken = store.state.companyBranches.companyBranchToken

  // set cancel token to config
  const axiosSource = axios.CancelToken.source()
  config.cancelToken = axiosSource.token
  config.withCredentials = true

  // add cancel token to store
  store.dispatch('axiosCancelTokens/addCancelToken', { source: axiosSource })

  let headers = {
    'app-company-token': companyBranchToken || companyToken || '',
    Accept: 'application/json'
  }

  if (authToken) {
    headers = {
      ...headers,
      Token: companyToken
    }
  }

  config.headers = headers

  const tokenNeedsToBeUpdated = getTokenNeedsToBeUpdated(store.getters['auth/authTokenExpiration'])

  if (!tokenNeedsToBeUpdated || !!refreshingToken) return ({ ...config })

  refreshingToken = true
  ky.post(`${env('API_BASE_URL')}/ms-companies/api/refresh-token`, {
    headers,
    cache: 'no-store',
    credentials: 'include'
  }).json()
    .then(response => {
      const {
        token: authToken,
        companyToken,
        userRole,
        expiration_unix_time: authTokenExpiration
      } = response

      store.dispatch('companyBranches/setPartialCompanyBranch', {
        companyToken,
        data: {
          userRole,
          authToken,
          authTokenExpiration,
          status: COMPANY_BRANCH_STATUS_ACTIVE
        }
      })

      config.headers = {
        Accept: 'application/json'
      }

      return ({ ...config })
    })
    .catch((error) => {
      console.error(error)

      store.dispatch('companyBranches/setPartialCompanyBranch', {
        companyToken,
        data: { status: COMPANY_BRANCH_STATUS_LOGGED_OUT }
      })
    })
    .finally(() => {
      refreshingToken = false
    })
},
(error) => Promise.reject(error)
)

apiClient.interceptors.response.use(response => response, async (error) => {
  const url = error.config.url
  const status = error?.response?.status
  const message = error?.response?.data?.message || ''

  // if is unspash error
  if (url.includes('unsplash')) {
    return Promise.reject(error)
  }

  // fatal error
  if (status >= 500) {
  }

  // too many request
  if (status === 429) {
  }

  // invalid data
  if (status === 422) {
    const paths = [API_LOGIN_PATH, API_RECOVER_PASSWORD_PATH]

    if (paths.includes(url)) return Promise.reject(error)

    const errors = error?.response?.data?.errors || {}
    const text = getErrorHTMLForSnackbar(message, errors)
    store.dispatch('snackbar/showSnackbarError', { text })
  }

  // conflict
  if (status === 409) {
  }

  // not found
  if (status === 404) {
  }

  // not permission
  if (status === 403) {
    store.dispatch('defaultLayout/setShowModalNotPermission', { show: true })
  }

  // not be authenticated
  if (status === 401) {
    if (url === API_ME_PATH) return Promise.reject(error)
  }
  // bad Request
  if (status === 400) {
  }

  return Promise.reject(error)
}
)

const { get, post, put, patch, delete: destroy } = apiClient

export { get, post, put, patch, destroy }
