export const ROLE_OLACLICK_ADMIN = 'admin-olaclick'
export const ROLE_OLACLICK_MANAGER = 'manager-olaclick'

export const ROLE_COMPANY_ADMIN = 'admin'
export const ROLE_COMPANY_MANAGER = 'manager'
/**
 * TODO: cuando backend termine la migracion, entonces podremos cambiar el valor de ROLE_COMPANY_WAITER a 'waiter', por ahora es 'sales'
 * confirmar el 2024-07-01
 */
export const ROLE_COMPANY_WAITER = 'sales'
export const ROLE_COMPANY_RIDER = 'rider'
export const ROLE_COMPANY_COOK = 'cook'

export const COMPANY_ROLES = [
  ROLE_COMPANY_ADMIN,
  ROLE_COMPANY_MANAGER,
  ROLE_COMPANY_WAITER,
  ROLE_COMPANY_RIDER
]
