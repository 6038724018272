<template>
  <v-app>
    <v-main>
      <slot>
        <!--  -->
      </slot>
    </v-main>

    <UserDeliveryModalOnboarding v-model="showDeliveryUserLoggedFirstInit" />

    <NotPermissionModal />

    <Snackbar />
    <UpgratePlanModal />

    <v-dialog
      v-model="showOfflineModal"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('offline') }}
        </v-card-title>

        <v-card-text class="pa-4">
          {{ $t('your_internet_connection_is_unstable') }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { NAVIGATION_DRAWER_WIDTH } from '@/constants/navigationDrawerWidth.js'
import UpgratePlanModal from '@/components/Subscription/UpgratePlanModal.vue'
import getBrowserLanguage from '@/plugins/i18n/helpers/getBrowserLanguage'
import { DELIVERY_USER_LOGGED } from '@/components/common/constants/namesOfIntroductoryMessageModals.js'

import {
  NOTIFICATION_PERMISSION_DEFAULT,
  NOTIFICATION_PERMISSION_DENIED
} from '@/constants/notificationPermissions.js'

export default {
  components: {
    NotPermissionModal: () => import('@/components/NotPermissionModal.vue'),
    Snackbar: () => import('@/components/common/Notification/Snackbar'),
    UserDeliveryModalOnboarding: () => import('@/components/User/UserDeliveryModalOnboarding.vue'),
    UpgratePlanModal
  },
  metaInfo () {
    return {
      title: `${this.userCompanyName} - OlaClick`
    }
  },
  data: () => ({
    NAVIGATION_DRAWER_WIDTH,
    blurPageTimestamp: null,
    showDeliveryUserLoggedFirstInit: false,
    eventListeners: [],
    showOfflineModal: false,
    panel: [0]
  }),
  computed: {
    ...mapState({
      userCompany: (state) => state.userCompany.userCompany,
      pageNamesWhereTheIntroductoryMessagesAreDisplayed: (state) => state.introMessages.pageNamesWhereTheIntroductoryMessagesAreDisplayed,
      downloads: state => state.downloads.downloads
    }),
    ...mapGetters({
      userHasCompanyRol: 'auth/userHasCompanyRol',
      userRoleIsCompanyRider: 'auth/userRoleIsCompanyRider',
      userCompanyName: 'userCompany/userCompanyName',
      userCompanyToken: 'userCompany/userCompanyToken'
    })
  },
  created () {
    this.setCurrentLanguage()
    if (this.userRoleIsCompanyRider) {
      this.checkIfIntroductoryMessagesWereDisplayed()
    }

    this.eventListeners = [
      ['visibilitychange', this.handleVisibilityChange],
      ['focus', this.handleFocus],
      ['blur', this.handleBlur],
      ['appinstalled', this.handleAppInstalled],
      ['online', this.handleOnline],
      ['offline', this.handleOffline]
    ]

    for (const [event, handler] of this.eventListeners) {
      window.addEventListener(event, handler)
    }
  },
  mounted () {
    this.handleNotificationPermission()
  },
  destroyed () {
    for (const [event, handler] of this.eventListeners) {
      window.removeEventListener(event, handler)
    }
  },
  methods: {
    ...mapActions({
      setPageNameWhereTheIntroMessagesAreDisplayed: 'introMessages/setPageNameWhereTheIntroMessagesAreDisplayed'
    }),
    urlBase64ToUint8Array (base64String = '') {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
      // eslint-disable-next-line no-useless-escape
      const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
      const rawData = atob(base64)
      const outputArray = new Uint8Array(rawData.length)

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }

      return outputArray
    },
    setCurrentLanguage () {
      const browserLanguage = getBrowserLanguage() // es || en || pt || fr || etc...
      const language = this.userCompany?.language && this.userHasCompanyRol ? this.userCompany?.language : browserLanguage
      this.$vuetify.lang.current = language.substring(0, 2)
    },
    handleNotificationPermission () {
      try {
        const notificationPermission = Notification.permission

        if (notificationPermission === NOTIFICATION_PERMISSION_DEFAULT) {
          Notification.requestPermission()
        }

        if (notificationPermission === NOTIFICATION_PERMISSION_DENIED) {
          console.log('Tiene las notificaciones desactivadas')
        }
      } catch (error) {
        console.error(error)
      }
    },
    handleFocus (e) {
      /**
       * - click al tab
       * - click al contenido
       */

      if (!this.userHasCompanyRol) {
        return
      }

      if (!this.blurPageTimestamp) {
        return
      }

      if (Date.now() - this.blurPageTimestamp < 5 * 60 * 1000) { // 5 minutes
        return
      }

      this.$bus.$emit('focusAfter5Minutes')
    },
    handleBlur (e) {
      this.blurPageTimestamp = Date.now()
    },
    handleVisibilityChange (e) {
      if (document.hidden) {
        this.handleBlur()
      } else {
        this.handleFocus()
      }
    },
    handleAppInstalled (e) {
      this.$bus.$emit('pwaInstalled', { companyToken: this.userCompanyToken })
    },
    handleOffline (e) {
      this.showOfflineModal = true
    },
    handleOnline (e) {
      this.$bus.$emit('windowOnline')

      setTimeout(() => {
        location.reload()
      }, 500)
    },
    checkIfIntroductoryMessagesWereDisplayed () {
      if (this.pageNamesWhereTheIntroductoryMessagesAreDisplayed.length > 0) {
        const wasFound = this.pageNamesWhereTheIntroductoryMessagesAreDisplayed
          .find(displayedMessage => displayedMessage.pageName === DELIVERY_USER_LOGGED)

        if (wasFound) {
          this.showDeliveryUserLoggedFirstInit = false
          return
        }
      }

      this.showDeliveryUserLoggedFirstInit = true
      this.setPageNameWhereTheIntroMessagesAreDisplayed({ pageName: DELIVERY_USER_LOGGED })
    }
  }
}
</script>

<style lang="scss" scoped>
.downloads-botton-sheet {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 3;
  max-width: 360px;
  min-width: 360px;
  width: 100%;
  margin: 0 12px;

  @media (max-width: 600px) {
    bottom: 0;
    max-width: 100%;
    min-width: 200px;
    left: 0;
    margin: 0;
  }
}
</style>
