import { get, post, put, patch, destroy } from '../base'
import { invalidateCache } from 'alova'

export const Company = {
  index: ({ params }) => get('/auth/companies', { params }),
  getById: ({ companyId }) => get(`/auth/companies/${companyId}`), // si va
  store: ({ data }) => post('/auth/companies/store', data),
  registerCompany: ({ data }) => post('/api/v1/companies/store', data),
  update: async ({ data, companyId }) => {
    try {
      const companyResponse = await put(`/auth/companies/${companyId}`, data)
      return companyResponse
    } catch (error) {
      throw new Error(error)
    } finally {
      invalidateCache()
    }
  },
  destroy: ({ companyId }) => destroy(`/api/companies/${companyId}`),
  clone: ({ companyId }, data) => post(`/auth/companies/${companyId}/clone-data`, data),
  downloadQr: ({ companyId }) => get(`/auth/companies/${companyId}/download-qr`, { responseType: 'blob' }),
  partialUpdate: ({ companyId, data }) => {
    return new Promise((resolve, reject) => {
      patch(`/auth/companies/${companyId}`, data)// si va
        .then((response) => {
          const company = response.data.data
          invalidateCache()
          resolve(company)
        })
        .then((error) => { reject(error) })
    })
  },
  getCompanyUsers: ({ companyId }) => get(`/auth/companies/${companyId}/users`),
  getBestCustomers: ({ params }) => {
    return new Promise((resolve, reject) => {
      get('/api/clients', { params })
        .then((response) => {
          const bestCustomers = response.data.data
          resolve(bestCustomers)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getBestProducts: (companyId, { params }) => {
    return new Promise((resolve, reject) => {
      get(`/auth/companies/${companyId}/best-products`, { params })
        .then((response) => {
          const bestProducts = response.data.data
          resolve(bestProducts)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getMostUsedTypesOfService: (companyId, { params }) => {
    return new Promise((resolve, reject) => {
      get(`/auth/companies/${companyId}/order-type-more-used`, { params })
        .then((response) => {
          const mostUsedTypesOfService = response.data.data
          resolve(mostUsedTypesOfService)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkIfTokenIsAvailable: (token) => {
    return new Promise((resolve, reject) => {
      get(`/auth/companies/check-token-exists/${token}`)
        .then((response) => {
          const data = response.data
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  changeToken: (companyId, data) => {
    return new Promise((resolve, reject) => {
      patch(`/auth/companies/${companyId}/change-token`, data)
        .then((response) => {
          const company = response.data.data
          resolve(company)
        })
        .catch((error) => { reject(error) })
    })
  },
  getCompanyRiderUsers: ({ companyId }) => get(`/auth/companies/${companyId}/riders`),
  getOrderRatings: ({ companyId, params }) => get(`/auth/companies/${companyId}/order-ratings`, { params }),
  getTotalOrderRatings: ({ companyId }) => get(`/auth/companies/${companyId}/order-ratings/totals`)
}
