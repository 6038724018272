<template>
  <v-dialog
    v-model="showSubscriptionPremiumModal"
    scrollable
    persistent
    max-width="600"
    no
  >
    <v-card :min-height="$vuetify.breakpoint.xsOnly ? CARD_HEIGHT_MOBILE : CARD_HEIGHT_DESKTOP">
      <v-btn
        class="v-btn--absolute-top-right"
        icon
        @click="setShowSubscriptionPremiumModal({ show: false })"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text class="pa-0 fill-height d-flex flex-column">
        <div
          class="header-modal pt-8 pb-5 flex-wrap"
          :class="{'header-modal__height-mobile': $vuetify.breakpoint.xsOnly}"
        >
          <div
            class="
              text-h6 text-sm-h4 text-h4
              font-weight-bold text-sm-left
              pr-2 pr-sm-6 black--text
            "
            :class="[$vuetify.breakpoint.xsOnly ? 'text-plan-update__mobile' : 'text-plan-update__desktop']"
          >
            {{ $t('upgrade_your_plan_to_use_this_feature') }}
          </div>
          <div
            class="pl-md-6 pl-2"
          >
            <v-img
              :height="$vuetify.breakpoint.xsOnly ? 80:123"
              :width="$vuetify.breakpoint.xsOnly ? 80:148"
              :src="require('@/components/Subscription/images/dining.svg')"
            />
          </div>
        </div>

        <div
          style="height: 50%;"
          class="mt-6 pb-8 px-8 px-sm-12 text-center d-flex align-center"
        >
          <div style="flex:1;">
            <div v-if="panelIsEmbedInFlutter || panelIsInIosMobile">
              {{ $t('enter_from_pc_mac_desktop_screen_to_update_your_plan') }}
              <span class="black--text">😎!</span>
            </div>
            <div
              v-else
              class="text-sm-body-1"
              style=" letter-spacing: 0px !important;width: 100%;"
            >
              {{ $t('more_tools_more_growth_and_more_support_only_available_with_the_premium_plan') }} <br>
              {{ $t('upgrade_your_plan_now_to_use_it') }}
            </div>

            <div
              v-if="panelIsEmbedInFlutter || panelIsInIosMobile"
            >
              <v-btn
                height="48"
                color="primary"
                class="text-none mt-5 text-subtitle-1 font-weight-bold pa-5"
                @click="setShowSubscriptionPremiumModal({ show: false })"
              >
                {{ $t('understood') }}
              </v-btn>
            </div>
            <div v-else>
              <v-btn
                height="48"
                color="primary"
                class="text-none mt-5 text-subtitle-1 font-weight-bold pa-5"
                :to="{name: 'pricing'}"
                @click="setShowSubscriptionPremiumModal({ show: false })"
              >
                {{ $t('see_benefits') }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

const CARD_HEIGHT_DESKTOP = 420
const CARD_HEIGHT_MOBILE = 296

export default {
  props: {
    value: { type: Boolean, default: false }
  },
  data () {
    return {
      CARD_HEIGHT_DESKTOP,
      CARD_HEIGHT_MOBILE
    }
  },
  computed: {
    ...mapState({
      showSubscriptionPremiumModal: (state) => state.subscription.showSubscriptionPremiumModal,
      panelIsEmbedInFlutter: (state) => state.flutter.panelIsEmbedInFlutter
    }),
    ...mapGetters({
      panelIsInIosMobile: 'flutter/panelIsInIosMobile'
    }),
    showModal: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    ...mapActions({
      setShowSubscriptionPremiumModal: 'subscription/setShowSubscriptionPremiumModal'
    })
  }
}
</script>
<style lang="scss" scoped>
.v-btn--absolute-top-right {
  position: absolute;
  top: 8px;
  right: 8px;
}
.header-modal {
  background: linear-gradient(145.52deg, #FDD835 20.36%, #FF9F00 79.64%, #FF9F00 79.64%), #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
  &__height-mobile {
    height: 148px;
  }
}
.text-plan-update {
  &__mobile {
    max-width: 166px;
    line-height: 1.5rem;
  }
  &__desktop {
    max-width: 331px
  }
}
</style>
