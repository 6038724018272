// regex copied from https://github.com/faisalman/ua-parser-js/blob/master/src/ua-parser.js#L309
// eslint-disable-next-line no-useless-escape
const NAVIGATOR_USER_AGENT_REGEX_SAFARI_MOBILE = /version\/([\w\.\,]+) .*mobile\/\w+ (safari)/i

const getBrowserIsSafariMobile = () => {
  if (!window?.navigator?.userAgent) { return false }
  return NAVIGATOR_USER_AGENT_REGEX_SAFARI_MOBILE.test(window.navigator.userAgent)
}

export default getBrowserIsSafariMobile
