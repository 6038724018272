// import { isMobile, isIOS } from 'mobile-device-detect'
import getPanelIsEmbedInFlutter from '@/flutter/helpers/getPanelIsEmbedInFlutter.js'
import panelOlaclickIsPwa from '@/helpers/panelOlaclickIsPwa.js'
import getBrowserIsSafariMobile from '@/helpers/getBrowserIsSafariMobile.js'

const state = () => ({
  panelIsEmbedInFlutter: getPanelIsEmbedInFlutter(),
  panelIsPWA: panelOlaclickIsPwa(),
  browserIsSafariMobile: getBrowserIsSafariMobile()
})

const getters = {
  panelIsInIosMobile: (state) => {
    // return (isIOS && isMobile)
    return false
  },
  panelIsInApp: (state) => {
    return state.panelIsEmbedInFlutter || state.panelIsPWA
  }
}

const actions = {
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
