export const LANGUAGE_EN = 'en'
export const LANGUAGE_ES = 'es'
export const LANGUAGE_ES_MX = 'es-MX'
export const LANGUAGE_PT = 'pt'
export const LANGUAGE_FR = 'fr'
export const LANGUAGE_AR = 'ar'

export const SUPPORTED_LANGUAGES = [
  LANGUAGE_ES,
  LANGUAGE_ES_MX,
  LANGUAGE_EN,
  LANGUAGE_PT,
  LANGUAGE_FR
]

export const FALLBACK_LANGUAGES_MAP = {
  [LANGUAGE_ES_MX]: LANGUAGE_ES,
  default: LANGUAGE_EN
}
