
export const PLAN_ADVANCED_PERIODS_1_IN_USD = parseFloat((10 * 1).toFixed(2))
export const PLAN_ADVANCED_PERIODS_3_IN_USD = parseFloat((9 * 3).toFixed(2))
export const PLAN_ADVANCED_PERIODS_6_IN_USD = parseFloat((8 * 6).toFixed(2))
export const PLAN_ADVANCED_PERIODS_12_IN_USD = parseFloat((7 * 12).toFixed(2))

export const PLAN_PREMIUM_PERIODS_1_IN_USD = parseFloat((27.14 * 1).toFixed(2))
export const PLAN_PREMIUM_PERIODS_3_IN_USD = parseFloat((24.43 * 3).toFixed(2))
export const PLAN_PREMIUM_PERIODS_6_IN_USD = parseFloat((21.71 * 6).toFixed(2))
export const PLAN_PREMIUM_PERIODS_12_IN_USD = parseFloat((19 * 12).toFixed(2))

export const PLAN_ADVANCED_IN_USD = PLAN_ADVANCED_PERIODS_1_IN_USD
export const PLAN_PREMIUM_IN_USD = PLAN_PREMIUM_PERIODS_1_IN_USD
