import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyGoogleAutocomplete from '@alvarosacari/vuetify-google-autocomplete'
import env from '@/env.js'

Vue.use(VueGoogleMaps, {
  load: {
    key: env('GOOGLE_API_KEY'),
    libraries: 'places,drawing'
  }
})

Vue.use(VuetifyGoogleAutocomplete, {
  vueGoogleMapsCompatibility: true
})
