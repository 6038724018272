import { DOMAIN_REGISTRATION_STATUS_ACTIVE } from '@/constants/domainRegistrationStatus.js'

const state = () => ({})
const getters = {
  defaultWebappUrl: (state, getters, rootState, rootGetters) => {
    const companyPublicUrl = rootGetters['userCompany/userCompanyPublicUrl']
    return companyPublicUrl
  },
  webappUrl: (state, getters, rootState, rootGetters) => {
    const userCompanyDomain = rootGetters['userCompany/userCompanyDomain']
    return userCompanyDomain?.registration_status === DOMAIN_REGISTRATION_STATUS_ACTIVE
      ? userCompanyDomain?.url
      : getters.defaultWebappUrl
  },
  defaultWebappProductsUrl: (state, getters, rootState, rootGetters) => {
    return `${getters.defaultWebappUrl}/products`
  },
  webappProductsUrl: (state, getters, rootState, rootGetters) => {
    const userCompanyDomain = rootGetters['userCompany/userCompanyDomain']
    return userCompanyDomain?.registration_status === DOMAIN_REGISTRATION_STATUS_ACTIVE
      ? `${userCompanyDomain?.url}/products`
      : getters.defaultWebappProductsUrl
  }
}
const actions = {}
const mutations = {}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
