import {
  PLAN_ADVANCED,
  PLAN_PREMIUM
} from '@/constants/plans.js'
import {
  PLAN_ADVANCED_PERIODS_1_IN_USD,
  PLAN_ADVANCED_PERIODS_3_IN_USD,
  PLAN_ADVANCED_PERIODS_6_IN_USD,
  PLAN_ADVANCED_PERIODS_12_IN_USD,
  PLAN_PREMIUM_PERIODS_1_IN_USD,
  PLAN_PREMIUM_PERIODS_3_IN_USD,
  PLAN_PREMIUM_PERIODS_6_IN_USD,
  PLAN_PREMIUM_PERIODS_12_IN_USD
} from '@/constants/planPricesInUSD.js'

const getPlanPeriodPriceInUSD = (planPeriod) => {
  if (planPeriod.plan_id === PLAN_ADVANCED && planPeriod.period === 1) {
    return PLAN_ADVANCED_PERIODS_1_IN_USD
  }
  if (planPeriod.plan_id === PLAN_ADVANCED && planPeriod.period === 3) {
    return PLAN_ADVANCED_PERIODS_3_IN_USD
  }
  if (planPeriod.plan_id === PLAN_ADVANCED && planPeriod.period === 6) {
    return PLAN_ADVANCED_PERIODS_6_IN_USD
  }
  if (planPeriod.plan_id === PLAN_ADVANCED && planPeriod.period === 12) {
    return PLAN_ADVANCED_PERIODS_12_IN_USD
  }
  if (planPeriod.plan_id === PLAN_PREMIUM && planPeriod.period === 1) {
    return PLAN_PREMIUM_PERIODS_1_IN_USD
  }
  if (planPeriod.plan_id === PLAN_PREMIUM && planPeriod.period === 3) {
    return PLAN_PREMIUM_PERIODS_3_IN_USD
  }
  if (planPeriod.plan_id === PLAN_PREMIUM && planPeriod.period === 6) {
    return PLAN_PREMIUM_PERIODS_6_IN_USD
  }
  if (planPeriod.plan_id === PLAN_PREMIUM && planPeriod.period === 12) {
    return PLAN_PREMIUM_PERIODS_12_IN_USD
  }

  return 0
}

export default getPlanPeriodPriceInUSD
