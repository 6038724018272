import {
  SET_NOTIFICATIONS_SETTINGS,
  SET_SETTINGS_CHATBOT_DATA
} from '@/store/mutationTypes.js'
import { useRequest } from 'alova'
import notificationsMS from '@/services/notificationsMS'
import getDataToUpdateChatbotMessage from '@/helpers/getDataToUpdateChatbotMessage'
import sendPostMessageToParent from '@/helpers/sendPostMessageToParent'

const state = () => ({
  notificationsSettings: null
})

const getters = {
  notificationsSettingsChatbotMessage: (state) => {
    if (!state.notificationsSettings) return null
    return state.notificationsSettings?.chatbot_settings?.messages || null
  }
}

const {
  send: sendGetSettings
} = useRequest(() => notificationsMS.getSettings(), { immediate: false })

const {
  send: sendPatchChatbotSettings
} = useRequest((data) => notificationsMS.patchChatbotSettings(data), { immediate: false })

const actions = {
  async getNotificationsSettings ({ commit, state, getters }) {
    try {
      const notificationsSettings = await sendGetSettings()
      commit(SET_NOTIFICATIONS_SETTINGS, notificationsSettings)
    } catch (error) {
      console.error(error)
    }
  },
  async patchChatbotSettings ({ commit, state, getters }, data) {
    try {
      const notificationSettingsPatched = await sendPatchChatbotSettings(data)
      sendPostMessageToParent({
        type: 'POSTMESSAGE_TYPE_INVALIDATE_CACHE',
        data: 'getNotificationsSettings'
      })
      commit(SET_NOTIFICATIONS_SETTINGS, notificationSettingsPatched)
    } catch (error) {
      console.error(error)
    }
  },
  setSettingsChatbotData ({ commit }, { groupKey, data }) {
    commit(SET_SETTINGS_CHATBOT_DATA, { groupKey, data })
  }
}

const mutations = {
  [SET_NOTIFICATIONS_SETTINGS] (state, settings) {
    const settingsChatbotMessage = settings.chatbot_settings?.messages || null
    settings.chatbot_settings = getDataToUpdateChatbotMessage(settingsChatbotMessage)

    state.notificationsSettings = settings
  },
  [SET_SETTINGS_CHATBOT_DATA] (state, { groupKey, data }) {
    if (groupKey === 'orders') {
      for (const [key, value] of Object.entries(data)) {
        state.notificationsSettings.chatbot_settings.messages[groupKey].status_update[key] = value
      }
    } else {
      for (const [key, value] of Object.entries(data)) {
        state.notificationsSettings.chatbot_settings.messages[groupKey][key] = value
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
