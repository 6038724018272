import {
  SET_COMPANY_LAYOUT_SETTINGS,
  SET_LAYOUT_SETTINGS_LANDING_DATA,
  SET_LAYOUT_SETTINGS_PRODUCT_DATA
} from '@/store/mutationTypes.js'

import { CompanySettings as CompanySettingsAPI } from '@/api/companySettings'
import { LAYOUT_SETTINGS_DEFAULT } from '@/constants/layoutSettings.js'
import sendPostMessageToParent from '@/helpers/sendPostMessageToParent.js'

const state = () => ({
  layoutSettings: null
})
const getters = {
  layoutSettingsLanding: (state, getters) => {
    if (!state.layoutSettings) return null
    return state.layoutSettings.landing
  },
  layoutSettingsLandingBody: (state, getters) => {
    if (!state.layoutSettings) return null
    return state.layoutSettings?.landing?.body || null
  },
  layoutSettingsLandingTitle: (state, getters) => {
    if (!state.layoutSettings) return null
    return state.layoutSettings?.landing?.title || null
  },
  layoutSettingsLandingFotter: (state, getters) => {
    if (!state.layoutSettings) return null
    return state.layoutSettings?.landing?.footer || null
  },
  layoutSettingsProduct: (state, getters) => {
    if (!state.layoutSettings) return ''
    return state.layoutSettings?.product
  }
}

const actions = {
  setCompanyLayoutSettings ({ commit }, { layoutSettings }) {
    commit(SET_COMPANY_LAYOUT_SETTINGS, { layoutSettings })
  },
  setLayoutSettingsLandingData ({ commit }, { groupKey, data }) {
    commit(SET_LAYOUT_SETTINGS_LANDING_DATA, { groupKey, data })
  },
  setLayoutSettingsProductData ({ commit }, { key, value }) {
    commit(SET_LAYOUT_SETTINGS_PRODUCT_DATA, { key, value })
  },
  async partialUpdateLayoutSettings ({ getters, commit, rootGetters }, { data }) {
    try {
      const companySettings = await CompanySettingsAPI.partialUpdateLayoutSettings({ data })
      const layoutSettings = companySettings.layout_settings

      commit(SET_COMPANY_LAYOUT_SETTINGS, { layoutSettings })
      sendPostMessageToParent({
        type: 'POSTMESSAGE_TYPE_INVALIDATE_CACHE',
        data: 'getEcommerceSettings'
      })
      return layoutSettings
    } catch (error) {
      throw new Error(error)
    }
  }
}

const mutations = {
  [SET_COMPANY_LAYOUT_SETTINGS] (state, { layoutSettings }) {
    state.layoutSettings = layoutSettings || JSON.parse(JSON.stringify(LAYOUT_SETTINGS_DEFAULT))
  },
  [SET_LAYOUT_SETTINGS_LANDING_DATA] (state, { groupKey, data }) {
    for (const [key, value] of Object.entries(data)) {
      state.layoutSettings.landing[groupKey][key] = value
    }
  },
  [SET_LAYOUT_SETTINGS_PRODUCT_DATA] (state, { key, value }) {
    state.layoutSettings.product[key] = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
