import { SET_SHOW_SUBSCRIPTION_PREMIUM_MODAL } from '@/store/mutationTypes.js'

const state = () => ({
  showSubscriptionPremiumModal: false
})

const getters = {
}

const actions = {
  setShowSubscriptionPremiumModal ({ commit }, { show }) {
    commit(SET_SHOW_SUBSCRIPTION_PREMIUM_MODAL, { show })
  }
}

const mutations = {
  [SET_SHOW_SUBSCRIPTION_PREMIUM_MODAL] (state, { show }) {
    state.showSubscriptionPremiumModal = show
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
